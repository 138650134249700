<template>
  <AppsChartListItem
  v-for="(movie, index) in chart" 
  v-bind:index="index" 
  v-bind:theme="theme" 
  v-bind:movie="movie" />
</template>
<script>
import AppsChartListItem from '@/components/apps/AppsChartListItem.vue';
import { mapState, mapActions } from 'vuex';
export default {
  components: {
    AppsChartListItem,
  },
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.fetchChartPortion();
  },
  computed: {
    ...mapState({
      chart: state => state.chartGogi.movies,
    }),
  },
  methods: {
    ...mapActions({
      fetchChartPortion: 'chartGogi/fetchChartPortion',
    }),
  },
}
</script>
