<template>
  <div class="history-card history_gap  history-card_red ">
    <span class="history-card__icon  history-card__icon_red "></span>
    <div class="history-card__text  history-card__text_red ">{{ request }}</div>
  </div>
</template>

<script>
export default {
  props: {
    request: {
      type: String,
      required: true,
    },
  },
}
</script>
