<template>
  <div class="ta-movies-grid">
    <div v-for="film in films" class="ta-movies-grid__item">
      <div class="ta-movies-grid__card">
        <div class="ta-movies-grid__card-img">
          <img :src="film.img" :alt="film.title" />
        </div>
        <h4 class="ta-movies-grid__card-title">{{ film.title }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({
      films: [
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Земля кочевников' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Лига справедливости' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Разжимая кулаки' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Петровы в гриппе' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Купе номер 6' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Титан' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Вестсайдская история' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Аннетт' },
      ],
    })
  }
</script>
