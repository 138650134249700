<template>
  <div class="bill-item-wrap">
    <div class="bill-item">
      <div class="bill-item__date">{{ receipt.date }}</div>
      <button v-on:click="sendReceipt" class="bill-item__btn">
        <span class="bill-item__icon"></span>
        <span class="bill-item__text">Смотреть</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
import { mapState } from 'vuex';
export default {
  props: {
    receipt: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState({
      userId: state => state.userData.id,
    }),
  },
  methods: {
    sendReceipt() {
      axios(serviceApi, {
        params: {
          target: 'show-receipt',
          receiptId: this.receipt.id,
          receiptDate: this.receipt.date,
          userId: this.userId,
        }
      });
      Telegram.WebApp.close();
    },
  },
}
</script>
