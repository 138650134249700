import { createStore } from 'vuex';
import userData from '@/store/userData';
import allTariffs from '@/store/allTariffs';
import subscribeAction from '@/store/subscribeAction';
import userHistory from '@/store/userHistory';
import payTinkoff from '@/store/payTinkoff';
import chartGogi from '@/store/chartGogi';

export default createStore({
  modules: {
    userData,
    allTariffs,
    subscribeAction,
    userHistory,
    payTinkoff,
    chartGogi,
  },
});
