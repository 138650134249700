<template>
  <div class="ta-skill-card">
    <div class="ta-skill-card__top">
      <div class="ta-skill-card__price">
        <div class="ta-price">
          <div class="ta-price__inner">
            <b>128₽</b><br />месяц
          </div>
        </div>
      </div>
      <div class="ta-skill-card__img ta-skill-card__img--image">
        <img src="/assets/images/appIcon.svg" alt="">
      </div>
      <h2 class="ta-skill-card__title">Поиск фильмов</h2>
      <div class="ta-skill-card__hint">
        <b>Неограниченый поиск фильмов, сериалов</b><br />
        Возможность найти кино-новинки одним из самых первых
      </div>
    </div>
    <ul class="ta-action-list ta-skill-card__list">
      <li class="ta-action-list__item ta-action-list__item--new ta-action-list__item--premium">
        <div class="ta-action-list__img">
          <img src="/assets/images/premium.jpg" alt="">
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">Telegram Premium</h3>
          <div class="ta-action-list__desc">Подписка Telegram Primium в подарок</div>
        </div>
      </li>
      <li class="ta-action-list__item">
        <div class="ta-action-list__img">
          <svg width="32" height="32">
            <use xlink:href="/assets/images/sprite.svg#action-search-screen-icon" />
          </svg>
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">Поиск по скрину</h3>
          <div class="ta-action-list__desc">Поделись скрином и узнай название фильма</div>
        </div>
      </li>
      <li class="ta-action-list__item">
        <div class="ta-action-list__img">
          <svg width="32" height="32">
            <use xlink:href="/assets/images/sprite.svg#action-search-desc-icon" />
          </svg>
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">Поиск по описанию</h3>
          <div class="ta-action-list__desc">Отправь боту краткий пересказ фильма и он найдет его с точностью до 98%</div>
        </div>
      </li>
      <li class="ta-action-list__item">
        <div class="ta-action-list__img">
          <svg width="32" height="32">
            <use xlink:href="/assets/images/sprite.svg#action-search-icon" />
          </svg>
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">Неограниченный поиск</h3>
          <div class="ta-action-list__desc">ПВведи свои запросы с Yandex или Google в GoGiBey</div>
        </div>
      </li>
      <li class="ta-action-list__item">
        <div class="ta-action-list__img">
          <svg width="32" height="32">
            <use xlink:href="/assets/images/sprite.svg#action-search-genre-icon" />
          </svg>
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">По жанру</h3>
          <div class="ta-action-list__desc">Впиши в поиск нужный жанр</div>
        </div>
      </li>
      <li class="ta-action-list__item">
        <div class="ta-action-list__img">
          <svg width="32" height="32">
            <use xlink:href="/assets/images/sprite.svg#action-actor-icon" />
          </svg>
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">Актер</h3>
          <div class="ta-action-list__desc">Подписка Telegram Primium в подарок</div>
        </div>
      </li>
      <li class="ta-action-list__item">
        <div class="ta-action-list__img">
          <svg width="32" height="32">
            <use xlink:href="/assets/images/sprite.svg#action-like-icon" />
          </svg>
        </div>
        <div class="ta-action-list__wrapper">
          <h3 class="ta-action-list__title">Реакции</h3>
          <div class="ta-action-list__desc">Ставь уникальные рекции на фильм, смотри реакции на выдачу других фильмов</div>
        </div>
      </li>
    </ul>
    <button class="ta-skill-card__button">Открыть Skil</button>
    <div class="ta-skill-card__desc">
      <h2 class="ta-skill-card__desc-title">Описание поиск фильмов</h2>
      <div class="ta-skill-card__desc-text">
        <p>Запасайтесь попкорном  и <b>ищите любые фильмы</b> от старых добрых комедий до нового экшена с умопомрачительными спецэффектами.</p>

        <p><b>Найдем нужный фильм,</b> даже если вы помните только сюжет, одну фразу или саундтрек.</p>

        <p><b>Неограниченные критерии запроса.</b><br />
        Поиск по всем фильмам мировой киноиндустрии.<br />
        Единственный быстрый — 98% новинок найдете в сервисе на следующий день после выхода в прокат.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
