<template>
  <div class="ta-skill-page">
    <div class="ta-skill-page__body">
      <h1 class="ta-skill-page__title">Выберите свой skil</h1>
      <AppsSkillGrid />
    </div>
  </div>

  <section class="ta-skill-modal">
    <div class="ta-skill-modal__inner">
      <div class="ta-skill-modal__price">
        <div class="ta-price">
          <div class="ta-price__inner">
            <b>128₽</b><br />месяц
          </div>
        </div>
      </div>
      <button class="ta-skill-modal__close-btn">
        <svg width="20" height="20">
          <use xlink:href="/assets/images/sprite.svg#close-circle-icon" />
        </svg>
      </button>
      <div class="ta-skill-modal__top">
        <div class="ta-skill-modal__img ta-skill-modal__img--image">
          <img src="/assets/images/appIcon.svg" alt="">
        </div>
        <h3 class="ta-skill-modal__title">
          <b>Skil:</b> ZARA
        </h3>
        <p class="ta-skill-modal__tariff">Тариф: <b>START</b></p>
      </div>
      <div class="ta-skill-modal__controls">
        <label class="ta-check">
          <input type="checkbox">
          <div class="ta-check__box"></div>
          Оформляя подписку Skil “ZARA”, вы принимаете условия
          <a href="#">Договор оферты, Согласие на подписку услуги, стоимость и порядок оплаты</a> GoGIBey.
        </label>
      </div>
      <footer class="ta-skill-modal__footer">
        <button class="ta-skill-modal__button ta-skill-modal__button--loading ta-skill-modal__button--primary">Подключить за 300,00 ₽ в мес</button>
        <button class="ta-skill-modal__button ta-skill-modal__button--loading">Выбрать другой тариф</button>
      </footer>
    </div>
  </section>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsSkillGrid from '@/components/apps/AppsSkillGrid.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsSkillGrid,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
