<template>
  <template v-if="isAuth">
    <a v-on:click="openProfile" class="ta-skill-grid__item ta-skill-grid__item--image ta-skill-grid__item--user-profile">
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <img v-if="photoUrl !== ''" v-bind:src="photoUrl" alt="Иконка профиля" />
          <img v-if="photoUrl === '' && theme === 'light'" src="/assets/icons/no-photo-white.png" alt="Иконка профиля" />
          <img v-if="photoUrl === '' && theme === 'dark'" src="/assets/icons/no-photo-black.png" alt="Иконка профиля" />
        </div>
        <!-- <div class="ta-skill-grid__img-count">2</div> -->
      </div>
      <h3 class="ta-skill-grid__title">Профиль</h3>
    </a>
  </template>
</template>

<script>
export default {
  props: {
    photoUrl: {
      type: String,
      required: true,
    },
    isAuth: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },

  methods: {
    openProfile() {
      Telegram.WebApp.HapticFeedback.selectionChanged();
      this.$router.push('/profile-menu');
    },
  },
}
</script>
