<template>
  <div class="ta-skill-page">
    <div class="ta-skill-page__body">
      <h1 class="ta-skill-page__title">Выберите свой skil</h1>
      <AppsSkillGrid />
    </div>
  </div>

  <section class="ta-slider-modal">
    <div class="ta-slider-modal__wrapper">
      <button class="ta-slider-modal__close-btn">
        <svg width="10" height="10">
          <use xlink:href="/assets/images/sprite.svg#close-icon" />
        </svg>
      </button>
      <div class="ta-slider-modal__carousel">
        <AppsSkillSlider />
      </div>
      <div class="ta-slider-modal__footer">
        <button class="ta-button">
          Добавить Skil
          <img src="/assets/images/appIcon.svg" alt="" class="ta-button__icon">
        </button>
      </div>
    </div>
  </section>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsSkillGrid from '@/components/apps/AppsSkillGrid.vue';
  import AppsSkillSlider from '@/components/apps/AppsSkillSlider.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsSkillGrid,
      AppsSkillSlider,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
