<template>
  <div class="ta-search-select-page">
    <div class="ta-input-file">
      <button class="ta-input-file__btn"></button>
      <div class="ta-input-file__img">
        <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=1000" alt="">
      </div>
      <div class="ta-input-file__footer">
        <div class="ta-input-file__label">Новое изображение</div>
      </div>
    </div>
    <div class="ta-scan-result">
      <h2 class="ta-scan-result__title">Кажется, на скрине</h2>
      <article class="ta-movie-card ta-movie-card--sm">
        <img class="ta-movie-card__bg" src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=1000" />

        <div class="ta-movie-card__wrapper">

          <header class="ta-movie-card__header">
            <div class="ta-movie-card__header-wrapper">
              <ul class="ta-movie-card__rating">
                <li class="ta-movie-card__rating-item ta-movie-card__rating-item--imdb">
                  <span class="ta-movie-card__rating-count">8,3</span>
                  <span class="ta-movie-card__rating-label">IMDb</span>
                </li>
                <li class="ta-movie-card__rating-item ta-movie-card__rating-item--kinopoisk">
                  <span class="ta-movie-card__rating-count">7,9</span>
                  <span class="ta-movie-card__rating-label">Kinopoisk</span>
                </li>
              </ul>
              <h1 class="ta-movie-card__title">Миньоны: Грювитация</h1>
            </div>
            <div class="ta-movie-card__mini-img">
              <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=1000" alt="">
            </div>
          </header>

          <div class="ta-movie-card__emoji">
            <ul class="ta-emoji-list">
              <li class="ta-emoji-list__item">
                <img src="/assets/icons/emoji-nosub.svg" alt="">
                <span class="ta-emoji-list__item-count">64</span>
              </li>
              <li class="ta-emoji-list__item">
                <img src="/assets/icons/emoji-sub-notactive.svg" alt="">
                <span class="ta-emoji-list__item-count">81</span>
              </li>
              <li class="ta-emoji-list__item">
                <img src="/assets/icons/PRO.svg" alt="">
                <span class="ta-emoji-list__item-count">70</span>
              </li>
            </ul>
          </div>

          <ul class="ta-movie-card__stats">
            <li>2022</li>
            <li>США</li>
            <li>139 мин</li>
          </ul>

          <div class="ta-movie-card__genre">
            мультфильм, фантастика, комедия, криминал, приключения, семейный
          </div>

          <div class="ta-movie-card__actions">
            <a href="#0" class="ta-movie-card__actions-item ta-movie-card__actions-item--open">Открыть в...</a>
            <a href="#0" class="ta-movie-card__actions-item ta-movie-card__actions-item--trailer">Трейлер</a>
            <a href="#0" class="ta-movie-card__actions-item ta-movie-card__actions-item--bookmark"></a>
          </div>

          <footer class="ta-movie-card__footer">
            <p class="ta-movie-card__footer-text">
              Бывший морпех, страдающий от диссоциативного
              расстройства, внезапно получает власть
              и силы египетского
            </p>
            <a href="#0" class="ta-movie-card__footer-link">Еще</a>
          </footer>
        </div>
      </article>
    </div>
    <div class="ta-sm-grid">
      <h2 class="ta-sm-grid__title">Возможные варианты</h2>
      <div class="ta-sm-grid__list">
        <AppsMoviesGrid />
      </div>
    </div>
    <div class="ta-select-images">
      <h2 class="ta-select-images__title">Выберите из похожих  изображений</h2>
      <div class="ta-select-images__grid">
        <div class="ta-select-images__item">
          <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300" alt="">
        </div>
        <div class="ta-select-images__item">
          <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300" alt="">
        </div>
        <div class="ta-select-images__item">
          <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300" alt="">
        </div>
        <div class="ta-select-images__item">
          <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsMoviesGrid from '@/components/apps/AppsMoviesGrid.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsMoviesGrid,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
