<template v-if="isAuth">
  <div class="center search-settings">
    <header class="search-settings__header">Поиск фильмов:<br> <span
        class="search-settings__header_additional-color">настройка</span></header>
    <div class="search-settings__options">

      <label class="search-option search-settings__item" data-name="opentask">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/opentask-dark.svg');--background-image-light: url('/assets/icons/opentask-light.svg');width: 2rem;height: 2rem;"></span>
        </div>
        <div class="search-option__name">Open Task</div>
        <!-- <input type="checkbox" class="search-option__toggler" checked/> -->
        <input type="checkbox" class="search-option__toggler" v-model="serviceMovieOpentask"
          @change="changeServiceMovieOpentask" />
        <i class="search-option__switch"></i>
      </label>

      <label class="search-option search-settings__item" data-name="yandex">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/yandex.svg');--background-image-light: url('/assets/icons/yandex.svg');width: 2rem;height: 2rem;"></span>
        </div>
        <div class="search-option__name">Яндекс</div>
        <!-- <input type="checkbox" class="search-option__toggler" checked/> -->
        <input type="checkbox" class="search-option__toggler" v-model="serviceMovieYandex"
          @change="changeServiceMovieYandex" />
        <i class="search-option__switch"></i>
      </label>

      <!-- <label class="search-option search-settings__item" data-name="kinopoisk">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/kinopoisk.svg');--background-image-light: url('/assets/icons/kinopoisk.svg');width: 1.6875rem;height: 1.6875rem;"></span>
        </div>
        <div class="search-option__name">Кинопоиск</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="netflix">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/netflix.svg');--background-image-light: url('/assets/icons/netflix.svg');width: 1rem;height: 1.8125rem;"></span>
        </div>
        <div class="search-option__name">Netflix</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="ivi">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/ivi.svg');--background-image-light: url('/assets/icons/ivi.svg');width: 2.3125rem;height: 1.25rem;"></span>
        </div>
        <div class="search-option__name">ivi Cinema</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="okko">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/okko-dark.svg');--background-image-light: url('/assets/icons/okko-light.svg');width: 2.4375rem;height: 0.875rem;"></span>
        </div>
        <div class="search-option__name">Okko</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="wink">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/wink-dark.svg');--background-image-light: url('/assets/icons/wink-light.svg');width: 2.75rem;height: 1.1875rem;"></span>
        </div>
        <div class="search-option__name">Wink</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="amediateka">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/amediateka-dark.svg');--background-image-light: url('/assets/icons/amediateka-light.svg');width: 1.9375rem;height: 1.9375rem;"></span>
        </div>
        <div class="search-option__name">Amediateka</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="start">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/start-tv.svg');--background-image-light: url('/assets/icons/start-tv.svg');width: 2.375rem;height: 1.1875rem;"></span>
        </div>
        <div class="search-option__name">Start</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="megafon">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/megafon-dark.svg');--background-image-light: url('/assets/icons/megafon-light.svg');width: 1.875rem;height: 1.875rem;"></span>
        </div>
        <div class="search-option__name">Megafon</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="moretv">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/moretv-dark.svg');--background-image-light: url('/assets/icons/moretv-light.svg');width: 2.8125rem;height: 0.5rem;"></span>
        </div>
        <div class="search-option__name">More TV</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="premier">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/premier-dark.svg');--background-image-light: url('/assets/icons/premier-light.svg');width: 2.9375rem;height: 0.5625rem;"></span>
        </div>
        <div class="search-option__name">Premier</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="megogo">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/megogo-dark.svg');--background-image-light: url('/assets/icons/megogo-light.svg');width: 3.125rem;height: 0.8125rem;"></span>
        </div>
        <div class="search-option__name">Megogo</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

      <!-- <label class="search-option search-settings__item" data-name="kion">
        <div class="search-option__icon">
          <span class="search-option__icon-image"
            style="--background-image-dark: url('/assets/icons/kion-dark.svg');--background-image-light: url('/assets/icons/kion-light.svg');width: 2.625rem;height: 1.3125rem;"></span>
        </div>
        <div class="search-option__name">Kion</div>
        <input type="checkbox" class="search-option__toggler" />
        <i class="search-option__switch"></i>
      </label> -->

    </div>
  </div>
  <div id="notification" class="notification notification_hide">
    <div class="notification__text js-notification-text"></div>
    <div class="notification__close-button js-close-notification">Скрыть</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
import loadScript from '@/mixins/loadScript';
export default {
  mixins: [tgTheme, loadScript, tgBackButton],
  async created() {
    if (!this.isAuth) {
      await this.fetchUserData({
        initData: Telegram.WebApp.initData,
        initDataUnsafe: Telegram.WebApp.initDataUnsafe,
      });
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.userData.isAuth,
    }),
    serviceMovieOpentask: {
      get() {
        return this.$store.state.userData.servicesMovie.opentask;
      },
      set(value) {
        this.$store.commit('userData/setServiceMovie', { name: 'opentask', value });
      }
    },
    serviceMovieYandex: {
      get() {
        return this.$store.state.userData.servicesMovie.yandex;
      },
      set(value) {
        this.$store.commit('userData/setServiceMovie', { name: 'yandex', value });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchUserData: 'userData/fetchUserData',
      postServiceMovie: 'userData/postServiceMovie',
    }),
    isServices() {
      return this.serviceMovieOpentask || this.serviceMovieYandex;
    },
    changeServiceMovieOpentask(event) {
      if (!this.isServices()) {
        window.openNotification('Ошибка: нельзя отключить последнию функцию', 'orange', 2000);
        this.$store.commit('userData/setServiceMovie', { name: 'opentask', value: true });
      } 
      else if (this.serviceMovieOpentask) {
        window.openNotification(`Open Task: вкл`, 'blue', 2000);
      }
      this.postServiceMovie();
    },
    changeServiceMovieYandex(event) {
      if (!this.isServices()) {
        window.openNotification('Ошибка: нельзя отключить последнию функцию', 'orange', 2000);
        this.$store.commit('userData/setServiceMovie', { name: 'yandex', value: true });
      }
      else if (this.serviceMovieYandex) {
        window.openNotification('Yandex: вкл', 'blue', 2000);
      }
      this.postServiceMovie();
    },
  },
  mounted() {
    this.tgBackButtonVuex('/');
    document.body.classList.add('settings');

    // window.settings.onChangeSettings = (key, value) => {
      // console.log('Изменено значение', key, value);
      // if (key !== 'yandex' && value === true) {
      //   window.openNotification(`Данный функционал в разработке, скоро будет доступен`, 'orange', 2000);
      //   window.settings.setCheckboxValue(key, false);
      // }
      // if (key === 'yandex' && value === false) {
      //   window.openNotification(`Ошибка: нельзя отключить последнию функцию`, 'orange', 2000);
      //   window.settings.setCheckboxValue(key, true);
      // }
    // };
    window.initNotification();
    window.settings.init();
  },
  destroyed() {
    document.body.classList.remove('settings');
  },
}
</script>
