<template>
  <div class="center favorites">
    <header class="favorites__header">Избранное</header>
    <div class="favorites__list">
      <div class="movie favorites_gap" data-id=1>
        <div class="movie__image" style="background-image: url('/assets/images/LOTR.jpg')"></div>
        <div class="movie__info">
          <div class="movie__rating">
            <div class="imdb">
              <span class="imdb__rating ">9.9</span>
              <span class="imdb__name">IMDb</span>
            </div>
            <div class="kinopoisk">
              <span class="kinopoisk__rating ">9.2</span>
              <span class="kinopoisk__name">Kinopoisk</span>
            </div>
          </div>
          <header class="movie__title ">Властелин колец: Возвращение короля
            <span class="movie__year">(2003)</span>
          </header>
          <div class="movie__additional">
            <div class="movie__country">Новая зеландия, США</div>
            <span class="movie__divider"></span>
            <div class="movie__duration">105 мин</div>
          </div>
        </div>
        <span class="movie__menu"></span>
        <div class="tooltip" data-id=1>
          <button type="button" class="tooltip__share">
            <span class="tooltip__share_icon"></span>Поделиться
          </button>
          <button type="button" class="tooltip__delete">
            <span class="tooltip__delete_icon"></span>Удалить
          </button>
        </div>
      </div>
      <div class="movie favorites_gap" data-id=2>
        <div class="movie__image" style="background-image: url('/assets/images/home-alone.jpg')"></div>
        <div class="movie__info">
          <div class="movie__rating">
            <div class="imdb">
              <span class="imdb__rating imdb__rating_yellow">4.3</span>
              <span class="imdb__name">IMDb</span>
            </div>
            <div class="kinopoisk">
              <span class="kinopoisk__rating ">9.2</span>
              <span class="kinopoisk__name">Kinopoisk</span>
            </div>
          </div>
          <header class="movie__title  movie__title_watched"><span class="movie__title_watched-icon"></span>Один дома
          </header>
          <div class="movie__additional">
            <div class="movie__country">США</div>
            <span class="movie__divider"></span>
            <div class="movie__duration">103 мин</div>
          </div>
        </div>
        <span class="movie__menu"></span>
        <div class="tooltip" data-id=2>
          <button type="button" class="tooltip__share">
            <span class="tooltip__share_icon"></span>Поделиться
          </button>
          <button type="button" class="tooltip__delete">
            <span class="tooltip__delete_icon"></span>Удалить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
export default {
  mixins: [tgTheme, tgBackButton],
  mounted() {
    this.tgBackButtonVuex('/profile-menu');

    window.initFavorites();

    document.querySelectorAll('.movie .tooltip__share').forEach(shareButton => {
      shareButton.addEventListener('click', (event) => {
        event.preventDefault();
        console.log('поделиться фильмом ' + event.currentTarget.parentElement.dataset.id);
      })
    });

    document.querySelectorAll('.movie .tooltip__delete').forEach(shareButton => {
      shareButton.addEventListener('click', (event) => {
        event.preventDefault();
        console.log('удалить фильм ' + event.currentTarget.parentElement.dataset.id);
      })
    });

  },
}
</script>
