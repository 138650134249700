<template>
  <div class="ta-search-page">
    <div class="ta-search-page__hint">
      5 результатов по запросу <b>«Лучшие фильмы»</b>
    </div>
    <div class="ta-search-results-grid">
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card ta-search-results-grid__card--archive">
          <div class="ta-search-results-grid__card-wrapper">
            <div class="ta-search-results-grid__card-header">
              <div class="ta-search-results-grid__card-site">kinopoisk.ru</div>
            </div>
            <div class="ta-search-results-grid__card-img">
              <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
            </div>
          </div>
          <h3 class="ta-search-results-grid__card-title">Лучшие <b>популярные фильмы</b></h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card ta-search-results-grid__card--archive">
          <div class="ta-search-results-grid__card-wrapper">
            <div class="ta-search-results-grid__card-header">
              <div class="ta-search-results-grid__card-site">kinopoisk.ru</div>
            </div>
            <div class="ta-search-results-grid__card-img">
              <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
            </div>
          </div>
          <h3 class="ta-search-results-grid__card-title"><b>500</b> лучших <b>фильмов</b></h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card ta-search-results-grid__card--archive">
          <div class="ta-search-results-grid__card-wrapper">
            <div class="ta-search-results-grid__card-header">
              <div class="ta-search-results-grid__card-site">kinopoisk.ru</div>
            </div>
            <div class="ta-search-results-grid__card-img">
              <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
            </div>
          </div>
          <h3 class="ta-search-results-grid__card-title">Лучшие <b>популярные фильмы</b></h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card ta-search-results-grid__card--archive">
          <div class="ta-search-results-grid__card-wrapper">
            <div class="ta-search-results-grid__card-header">
              <div class="ta-search-results-grid__card-site">kinopoisk.ru</div>
            </div>
            <div class="ta-search-results-grid__card-img">
              <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
            </div>
          </div>
          <h3 class="ta-search-results-grid__card-title">Лучшие <b>фильмы 2021: Выбор редакции</b></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';

  export default {
    mixins: [tgTheme],
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
