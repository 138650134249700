/* Попытка активировать просроченную подписку, выполненную ранее пластиковой картой через Тинькоф 
 */
import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
export default {
  methods: {
    async activateTinkoffSubscription(userId) {
      window.showLoading({
        title: '<b>Активируем подписку</b> это займет несколько секунд',
      });
      const data = (await axios(serviceApi, {
        params: {
          target: 'tinkoff-activate',
          userId,
        }
      })).data;
      if (data.success) {
        this.$router.push(`/tariffs-list?payment=true&tariff=${data.tariffTitle}&activate=true`);
      }
      else {
        this.$router.push('/tariffs-list');
      }
    }
  },
}
