<template>
  <div class="ta-transfer-page">
    <div class="ta-transfer-page__wrapper">
      <header class="ta-transfer-page__header">
        <div class="ta-transfer-page__movie">
          <div class="ta-transfer-page__movie-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300" alt="">
          </div>
          <p class="ta-transfer-page__movie-name">Соник 2 в кино 2022</p>
        </div>
        <button class="ta-emoji-btn">
          Реакция
          <ul>
            <li>
              <img src="/assets/icons/emoji-nosub.svg" alt="">
            </li>
            <li>
              <img src="/assets/icons/emoji-sub-notactive.svg" alt="">
            </li>
            <li>
              <img src="/assets/icons/PRO.svg" alt="">
            </li>
          </ul>
        </button>
      </header>

      <div class="ta-transfer-page__body">
        <img src="/assets/images/movie-search.jpg" alt="">
      </div>
    </div>

    <div class="ta-transfer-page__footer">
      <div class="ta-transfer-page__footer-links">
        <a href="#0">о поиске</a>
        <a href="#0">помощь</a>

        <div class="ta-transfer-page__footer-tooltip">
          Работает на движке
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';

  export default {
    mixins: [tgTheme],
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
