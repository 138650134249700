/* Оплата пластиковой картой через Тинькоф
 * Выполняем запрос на бек, он возвращает нам ссылку на виджет оплаты
 * Выполняем переход по ссылке
 */
import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
export default {
  methods: {
    async payTinkoff(id, tariffKey) {
      window.showLoading({
        title: 'В течении нескольких секунд вы будете перенаправлены в банк для оформления тарифа',
      });
      const data = (await axios(serviceApi, {
        params: {
          target: 'tinkoff-init',
          userId: id,
          tariffKey,
          paymentType: 'first',
        }
      })).data;
      if (data.success) {
        window.location.href = data.url;
      }
      else {
        window.showError({
          title: 'Произошла ошибка',
          description: 'Пожалуйста, попробуйте еще раз',
          buttonText: 'Повторить',
          canClose: true,
          // onClose: () => {
          //   console.log('on close error');
          // },
          onRepeat: () => {
            // console.log('Повторить запрос');
            this.payTinkoff(id, tariffKey);
          },
        });
      }
    }
  },
}
