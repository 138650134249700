<template>
  <div class="manage-sub center" id="manage-sub">
    <div class="manage-sub-wrap">
      <div class="manage-sub__container">
        <img class="manage-sub__icon" src="/assets/icons/heart-blue.png" />
        <header class="manage-sub__title">Подписка действует ещё
          <span class="manage-sub__title_duration">{{tariff.daysLeft }} {{ getNounDay }}</span>
        </header>
        <div class="manage-sub__info">
          <div class="manage-sub__variant">Тариф: {{ tariff.title }}</div>
          <!-- <div class="manage-sub__date">Оформлен <b>1 июля 2022</b></div> -->
          <div v-if="status === 'debtor'" class="manage-sub__pricing manage-sub__pricing_line-through">
            {{ nextPrice }}&#8381; спишутся {{ endTarif }}
          </div>
          <div v-else class="manage-sub__pricing">{{ nextPrice }}&#8381; спишутся {{ endTarif }}</div>
        </div>
        <a href="#" v-if="status === 'debtor' && paymentSystem === 'tinkoff'" v-on:click="activateSubscription"
          class="button manage-sub__btn manage-sub__btn_gap  button_turquoise">
          Активировать подписку
        </a>
        <router-link to="/tariffs-list" class="button manage-sub__btn manage-sub__btn_gap  button_blue ">
          Изменить тариф
        </router-link>
        <a class="button manage-sub__btn js-cancel-subscription  button_transparent">
          Отключить подписку
        </a>
      </div>
    </div>
  </div>
  <div class="modal modal_hide" id="modal">
    <!-- <div class="elipsis-modal"></div> -->
    <span class="modal__close-btn js-close-modal"></span>
    <div class="modal__icon icon__warning"></div>
    <div class="modal__heading cancel-sub-modal__heading"></div>
    <div class="modal__text cancel-sub-modal__text"></div>
    <a class="button cancel-sub__btn manage-sub__btn_gap js-cancel-sub-top-btn  button_blue "></a>
    <a class="button cancel-sub__btn js-cancel-sub-bottom-btn  button_transparent"></a>
  </div>
  <div class="modal modal_hide" id="modal-standart">
    <div class="modal__elipsis"></div>
  </div>
  <div class="blur-background">
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
import loadScript from '@/mixins/loadScript';
import activateTinkoffSubscription from '@/mixins/activateTinkoffSubscription';
export default {
  mixins: [tgTheme, tgBackButton, loadScript, activateTinkoffSubscription],
  computed: {
    ...mapState({
      userId: state => state.userData.id,
      status: state => state.userData.status,
      paymentSystem: state => state.userData.paymentSystem,
      tariff: state => state.userData.tariff,
    }),
    ...mapGetters({
      endTarif: 'userData/endTarif',
      nextPrice: 'userData/nextPrice',
    }),
    getNounDay() {
      const n = Math.abs(this.tariff.daysLeft) % 100;
      let result = 'дней';
      if (n !== 11 && n % 10 === 1) result = 'день';
      if (!(n >= 12 && n <= 14) && n % 10 >= 2 && n % 10 <= 4) result = 'дня';
      return result;
    },
  },
  methods: {
    ...mapActions({
      removeSubscription: 'userData/removeSubscription',
    }),
    activateSubscription(event) {
      event.preventDefault();
      this.activateTinkoffSubscription(this.userId);
    },
  },
  mounted() {
    this.tgBackButtonVuex('/profile-menu');

    document.getElementsByClassName('js-cancel-subscription')[0].addEventListener('click', () => {
      window.manageSubscription.openWarning({
        title: 'Внимание',
        description: `При отключении подписки, оставшиеся <b>${this.tariff.daysLeft} ${this.getNounDay}</b> по тарифу автоматически <b>сгорают</b>`,
        cancelButtonText: 'Отключить',
        onCancel: () => {
          window.manageSubscription.openSaveDescription({
            text: 'Подписка сохранена',
            onClose: () => {
              this.$router.push('/profile-menu');
            },
          });
        },
        onConfirm: () => {
          window.manageSubscription.openSecondStepApprove({
            title: 'Вы действительно хотите отключить подписку GoGiBey?',
            yesButtonText: 'Да, отключить',
            noButtonText: 'Нет, сохранить',
            onCancel: () => {
              window.manageSubscription.openSaveDescription({
                text: 'Подписка сохранена',
                onClose: () => {
                  this.$router.push('/profile-menu');
                },
              });
            },
            onConfirm: async () => {
              const closeLoadingModal = window.showLoading({
                title: '<b>Ожидайте</b><br>это займет несколько секунд',
              });
              const isCanceled = await this.removeSubscription(this.userId);
              closeLoadingModal();
              // subscriptionCanceled - если подписка отключена, showError - если произошла ошибка
              if (isCanceled) {
                window.manageSubscription.subscriptionCanceled({
                  title: 'Подписка отключена',
                  description: 'Спасибо, что были с нами',
                  continueButtonText: 'Продолжить',
                  onContinue: () => {
                    // console.log('Нажато "продолжить"');
                    this.$router.push('/profile-menu');
                  },
                });
              } else {
                window.showError({
                  title: 'Ошибка',
                  description: 'Подписка не отключена, пожалуйста напишите в поддержку GoGiBey',
                  buttonText: 'Написать в поддержку',
                  canClose: true,
                  onClose: () => {
                    // console.log('Ошибка закрыта');
                  },
                  onRepeat: () => {
                    // console.log('Повторить запрос');
                    Telegram.WebApp.openTelegramLink('https://t.me/gogibey_online');
                  },
                });
              }
            },
          });
        },
      });
    });
    window.initModal();
  }
}
</script>