<template>
  <div class="documents center">
    <header class="documents__header">Документы</header>
    <div class="documents__list">
      <div class="menu-item-wrap">
        <a href=# class="menu-item" id="politic">
          <span class="menu-item__icon  menu-item__icon_gray">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/change.svg')"></span>
          </span>
          <div class="menu-item__text">Политика конфиденциальности</div>
        </a>
      </div>
      <div class="menu-item-wrap">
        <a href=# class="menu-item" id="offer">
          <span class="menu-item__icon  menu-item__icon_gray">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/change.svg')"></span>
          </span>
          <div class="menu-item__text">Публичная оферта</div>
        </a>
      </div>
      <div class="menu-item-wrap">
        <a href=# class="menu-item" id="price">
          <span class="menu-item__icon  menu-item__icon_gray">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/change.svg')"></span>
          </span>
          <div class="menu-item__text">Услуги и стоимость</div>
        </a>
      </div>
    </div>
    <footer class="documents__footer footer">
      <span class='footer__text'>Продолжая использовать чат-бот, а также услуги, предоставляемые с помощью чат-бота,
        пользователь выражает согласие с Договором-офертой и Политикой конфиденциальности чат-бота</span>
      <div class='footer__copyrights'>2022© Все права защищены</div>
    </footer>
  </div>
  <div class="modal modal_hide" id="modal">
    <!-- здесь можно описать шаблон модального окна и через js после openModal заполнять -->
    <span class="modal__close-btn js-close-modal"></span>
    <div class="documents-modal__title"></div>
    <div class="documents-modal__text"></div>
    <button class="modal__btn modal__btn_white">Закрыть</button>
  </div>
  <div class="blur-background">
  </div>
</template>

<script>
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
export default {
  mixins: [tgTheme, tgBackButton],
  mounted() {
    this.tgBackButtonVuex('/profile-menu');
    window.initModal();
    const politicsElement = document.getElementById('politic').addEventListener('click', (e) => {
      e.preventDefault()
      window.openDocumentModal({
        title: 'Политика конфиденциальности',
        text: "<p>Политика в <b>отношении обработки</b> персональных данных</p><p>1. Общие положения Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее – Оператор).</p><p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p><ul><li>то</li><li>сё</li></ul><a href=\"email:mail@mail.ru\">mail@mail.ru</a>"
      });
    });
    const offerElement = document.getElementById('offer').addEventListener('click', (e) => {
      e.preventDefault()
      window.openDocumentModal({
        title: 'Публичная оферта',
        text: "<p>Политика в <b>отношении обработки</b> персональных данных</p><p>1. Общие положения Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее – Оператор).</p><p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p><ul><li>то</li><li>сё</li></ul>"
      });
    });
    const priceElement = document.getElementById('price').addEventListener('click', (e) => {
      e.preventDefault()
      window.openDocumentModal({
        title: 'Услуги и стоимость',
        text: "<p>Политика в <b>отношении обработки</b> персональных данных</p><p>1. Общие положения Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее – Оператор).</p><p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p><ul><li>то</li><li>сё</li></ul>"
      });
    });
  },
}
</script>
