export default {
  methods: {
    async tgBackButtonVuex(urlBack) {
      if (!Telegram.WebApp.BackButton.isVisible) {
        Telegram.WebApp.BackButton.show();
      }
      Telegram.WebApp.BackButton.offClick();
      Telegram.WebApp.BackButton.onClick(() => { 
        // Telegram.WebApp.showAlert('tgBackButtonVuex');
        this.$router.push(urlBack); 
      });
    }
  },
}
