import { mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations({
      invertSucces: 'subscribeAction/invertSucces',
      invertFail: 'subscribeAction/invertFail',
      setStatus: 'userData/setStatus',
      setTariff: 'userData/setTariff',
    }),
    payCloudpayments(accountId, tariff) {
      const widget = new cp.CloudPayments();

      const publicId = process.env.VUE_APP_PUBLIC_ID;
      const tariffKey = tariff.key;
      const priceFirst = tariff.price;
      const daysFirst = tariff.days;

      const skin = Telegram.WebApp.colorScheme === 'light' ? 'mini' : 'modern';
      const description = `${daysFirst} ${this.getNoun(['день', 'дня', 'дней'], daysFirst)} подписки GoGiBey`;
      let d = new Date();
      d.setDate(d.getDate() + daysFirst);
      const startDate = d.toISOString();

      // чек для первого платежа
      const receiptFirst = {
        items: [
          {
            label: `${daysFirst} ${this.getNoun(['день', 'дня', 'дней'], daysFirst)} за ${priceFirst} ${this.getNoun(['рубль', 'рубля', 'рублей'], priceFirst)}`,
            price: priceFirst,
            quantity: 1,
            amount: priceFirst,
          },
        ],
      };

      // чек для рекурентного платежа
      let receiptRecurrent;
      let priceNext;
      let daysNext;
      if (!tariff.next) {
        priceNext = priceFirst;
        daysNext = daysFirst;
        receiptRecurrent = receiptFirst;
      } else {
        priceNext = tariff.next.price;
        daysNext = tariff.next.days;
        receiptRecurrent = {
          items: [
            {
              label: `${daysNext} ${this.getNoun(['день', 'дня', 'дней'], daysNext)} за ${priceNext} ${this.getNoun(['рубль', 'рубля', 'рублей'], priceNext)}`,
              price: priceNext,
              quantity: 1,
              amount: priceNext,
            },
          ],
        };
      }

      const data = {
        tariff: tariffKey, // для нашего api
        first: true, // для нашего api
        cloudPayments: {
          customerReceipt: receiptFirst,
          recurrent: {
            interval: 'Day',
            period: daysNext,
            startDate,
            amount: priceNext,
            customerReceipt: receiptRecurrent,
          },
        },
      };

      widget.charge(
        {
          publicId,
          description,
          amount: priceFirst,
          currency: 'RUB',
          accountId,
          skin,
          data,
        },
        () => {
          this.invertSucces();
          this.setStatus('active');
          this.setTariff({
            key: tariff.key,
            title: tariff.title,
            price: tariff.price,
            end: Date.now() + tariff.days * 1000 * 3600 * 24,
            daysLeft: tariff.days,
            titlePeriod: tariff.titlePeriod,
          });
          this.$router.push('/profile-menu');
        },
        () => {
          this.invertFail();
          this.$router.push('/profile-menu');
        },
      );
    },
    getNoun(nouns, value) { // ['день', 'дня', 'дней'], число
      const n = Math.abs(value) % 100;
      let result = nouns[2];
      if (n !== 11 && n % 10 === 1) result = nouns[0];
      if (!(n >= 12 && n <= 14) && n % 10 >= 2 && n % 10 <= 4) result = nouns[1];
      return result;
    },
  },
}
