import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
export default {
  state: () => ({
    history: [],
  }),
  mutations: {
    setHistory(state, history) {
      state.history = history;
    },
  },
  actions: {
    async fetchUserHistory(context, userId) {
      const history = (await axios(serviceApi, {
        params: {
          target: 'get-history',
          userId: userId,
        }
      })).data;
      context.commit('setHistory', history);
    },
  },

  namespaced: true,
}
