<template>
  <div class="center subscription">
    <header class="subscription__header">Подписка через СБП</header>
    <div class="subscription__variants">
      <template v-for="tariff in tariffsSbp">
        <TariffOneSbp v-bind:tariff="tariff"></TariffOneSbp>
      </template>
    </div>
    <div class="offer ">
      Оформляя подписку GoGiBey, вы принимаете условия
      <a class="offer__link" href="/profile/documents.html">
        Договор оферты Согласие на подписку Услуги, стоимость и порядок оплаты
      </a>
      GoGIBey.
    </div>
    <div class="spb-description">
      <img class="spb-description__image" src="/assets/icons/sbpnotext.svg" />
      <div class="spb-description__text">
        СБП система быстрых платежей, дальнейшая<br> подписка GoGiBey продливается в ручном режиме
      </div>
    </div>
    <div class="spb-copyrights">2022 © Все права защищены</div>
  </div>
  <div class="modal modal_hide" id="modal-standart">
    <div class="modal__elipsis"></div>
  </div>
  <div id="notification" class="notification notification_hide">
    <div class="notification__text js-notification-text"></div>
    <div class="notification__close-button js-close-notification">Скрыть</div>
  </div>
  <div class="blur-background">
  </div>
</template>

<script>
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
import payTinkoffSbp from '@/mixins/payTinkoffSbp';
import { mapState, mapActions } from 'vuex';
import TariffOneSbp from '@/components/profile/TariffOneSbp.vue';
export default {
  mixins: [tgTheme, tgBackButton, payTinkoffSbp],
  components: {
    TariffOneSbp,
  },
  async created() {
    await this.fetchTariffsSbp();
  },
  computed: {
    ...mapState({
      tariffsSbp: state => state.allTariffs.tariffsSbp,
    }),
  },
  methods: {
    ...mapActions({
      fetchTariffsSbp: 'allTariffs/fetchTariffsSbp',
    }),
  },
  mounted() {
    this.tgBackButtonVuex('/tariffs-list');
    window.initSubscriptionVariant();
    window.initModal();
    window.initNotification();

    // была произведена оплата
    // в этом случае ссылка на этй страницу может выглядеть как /tariffs-list-sbp?payment=true&tariff=PRO
    const isPaymment = this.$route.query.payment;
    if (isPaymment === 'true') {
      const tarifTitle = this.$route.query.tariff;
      window.showSuccess({
        title: 'Подписка успешно оформлена',
        description: `Тариф: ${tarifTitle}`,
        buttonOkText: 'Отлично',
        canClose: true,
        onClose: () => {
          // window.location.href = '#/';
          this.$router.push('/');
        },
      });
    }
    if (isPaymment === 'false') {
      window.showError({
        canClose: true,
        onClose: () => {
        },
        onRepeat: () => {
          this.payTinkoffSbp(this.userId, this.$route.query.tariff);
        },
      });
    }
  },
}
</script>
