<template>
  <!--  -->
  <button v-bind:onclick="payAction" class="subscription-btn subscription__item" v-bind:data-name="tariff.title">
    <div class="subscription-btn_blur"></div>
    <img class="subscription-btn__image" v-bind:src="`/assets/icons/${tariff.title}.png`" />
    <div class="subscription-btn__desc">
      <div class="subscription-btn__name">
        {{ tariff.title }}
      </div>
      <div class="subscription-btn__length">{{ tariff.titlePeriod }}</div>
    </div>
    <img class="subscription-btn__sbp" />
    <div class="subscription-btn__price">{{ tariff.price }}</div>
  </button>
</template>

<script>
import { mapState } from 'vuex';
import payTinkoffSbp from '@/mixins/payTinkoffSbp';
export default {
  mixins: [payTinkoffSbp],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      id: state => state.userData.id,
      // status: state => state.userData.status,
      // currentTariffTitle: state => state.userData.tariff.title,
    }),
  },
  methods: {
    payAction() {
      if (this.tariff.currentFlag) {
        window.openNotification(`Подписка ${this.tariff.title} уже активна!`, "orange", 2000);
      }
      this.payTinkoffSbp(this.id, this.tariff.key);
      // if (!this.tariff.currentFlag && this.status === 'active') {
      //   window.tariffChange.openApproveModal({
      //     text: `Вы действительно хотите поменять тариф на ${this.tariff.title}?`,
      //     yesText: 'Да, поменять',
      //     noText: `Нет, оставить ${this.currentTariffTitle}`,
      //     onClose: () => {
      //       // console.log('Окно закрыто');
      //     },
      //     onApprove: () => {
      //       // this.payCloudpayments(this.id, this.tariff); // переделать на передачу ключа?
      //       this.payTinkoff(this.id, this.tariff.key);
      //     },
      //   });
      // }
      // if (!this.tariff.currentFlag && this.status !== 'active') {
      //   this.payTinkoff(this.id, this.tariff.key);
      // }
    }
  },
}
</script>
