export default {
  data() {
    return {
      theme: Telegram.WebApp.colorScheme,
    }
  },
  mounted() {
    document.documentElement.className = Telegram.WebApp.colorScheme;
    this.theme = Telegram.WebApp.colorScheme;
    Telegram.WebApp.onEvent('themeChanged', () => {
      document.documentElement.className = Telegram.WebApp.colorScheme;
      this.theme = Telegram.WebApp.colorScheme;
    });
  },
}
