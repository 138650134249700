<template>
  <swiper
    slides-per-view="auto"
    :space-between="7"
    :free-mode="true"
    :autoHeight="true"
  >
    <swiper-slide v-for="(variant, index) in variants">
      <a href="actor.html" :class="['ta-sm-variants__card', index === 0 ? 'ta-sm-variants__card--active' : '']">
        <div class="ta-sm-variants__card-img">
          <img :src="variant.img" alt="">
        </div>
        <h3 class="ta-sm-variants__card-title">{{ variant.title }}</h3>
      </a>
    </swiper-slide>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data: () => ({
      variants: [
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          title: 'Оскар Айзек',
        },
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          title: 'Итан Хоук',
        },
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          title: 'Мэй Каламави',
        },
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          title: 'Гаспар Ульель',
        },
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          title: 'Мэй Каламави',
        },
      ]
    }),
  }
</script>
