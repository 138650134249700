<template>
  <div class="history-card history_gap ">
    <span class="history-card__icon "></span>
    <div class="history-card__text ">{{ request }}</div>
    <div class="history-card__image" 
      v-bind:style="`background-image: url('${image}')`"></div>
  </div>
</template>

<script>
  export default {
    props: {
      request: {
        type: String,
        required: true,
      },
      image: {
        type: String,
        required: true,
      },
    },
  }
  </script>
