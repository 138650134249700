import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;

export default {
  state: () => ({
    success: false,
    urlSPB: '',
  }),

  mutations: {
    setSuccess(state, success) {
      state.success = success;
    },
    setUrlSPB(state, urlSPB) {
      state.urlSPB = urlSPB;
    },
  },

  actions: {
    async fetchUrlSPB(context, params) {
      const result = (await axios(serviceApi, { params })).data;
      context.commit('setSuccess', result.success);
      context.commit('setUrlSPB', result.urlSPB);
    },
  },

  namespaced: true,
}
