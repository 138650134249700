<template>
  <button v-bind:onclick="payAction" data-name="NEW"
  class="subscription-btn subscription__item subscription-btn_special">
    <div class="subscription-btn_blur"></div>
    <div class="subscription-btn__star"></div>
    <img class="subscription-btn__image_special" src="/assets/icons/NEW.svg"/>
    <div class="subscription-btn__desc_special">
      <div class="subscription-btn__name_special">{{ tariff.title }}
        <span v-if="tariff.currentFlag" class="subscription-btn__special">Текущий</span>
        <span v-else class="subscription-btn__special">Пробный период</span>
      </div>
      <div class="subscription-btn__length_special">{{ tariff.titlePeriod }} за {{ tariff.price }}₽
        <span class="subscription-btn__divider"></span>
        <span v-if="tariff.next" class="subscription-btn__additional">далее {{ tariff.next.price }}₽ в мес</span>
      </div>
    </div>
    <div class="subscription-btn__price_special">{{ tariff.price }}</div>
  </button>
</template>

<script>
import payCloudpayments from '@/mixins/payCloudpayments';
import payTinkoff from '@/mixins/payTinkoff';
import { mapState } from 'vuex';
export default {
  mixins: [payCloudpayments, payTinkoff],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      id: state => state.userData.id,
      status: state => state.userData.status,
    }),
  },
  methods: {
    payAction() {
      if (this.tariff.currentFlag) {
        window.openNotification(`Подписка ${this.tariff.title} уже активна!`, "orange", 2000);
      } else {
        // this.payCloudpayments(this.id, this.tariff);
        this.payTinkoff(this.id, this.tariff.key);
      }
    }
  },
}
</script>
