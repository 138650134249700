<template>
  <div class="ta-skill-page">
    <div class="ta-skill-page__body">
      <h1 class="ta-skill-page__title">Выберите свой skil</h1>
      <AppsSkillGrid />
    </div>
    <div class="ta-skill-page__footer">
      <div class="ta-bottomsheet">
        <div class="ta-sm-loading ta-sm-loading--bottomsheet ta-bottomsheet__sm-loading">
          <div class="ta-sm-loading__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
              <path class="ta-sm-loading__icon-bg" d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"/>
              <path class="ta-sm-loading__icon-fill" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite" to="360 12 12" type="rotate"/>
              </path>
            </svg>
          </div>
          <div class="ta-sm-loading__text">Загрузка</div>
        </div>
        <button class="ta-button">
          Добавить Skil
          <img src="/assets/images/appIcon.svg" alt="" class="ta-button__icon">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsSkillGrid from '@/components/apps/AppsSkillGrid.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsSkillGrid,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
