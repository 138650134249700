<template>
  <noscript>
    <div><img src="https://mc.yandex.ru/watch/92920105" style="position:absolute; left:-9999px;" alt="" /></div>
  </noscript>

  <div class="center subscription">
    <header v-if="status === 'new'" class="subscription__header">Выберите тариф:</header>
    <header v-if="status === 'old' || status === 'debtor' || status === 'hold exit'" class="subscription__header">Продлить
      подписку</header>
    <header v-if="status === 'active'" class="subscription__header">Изменить тариф</header>
    <div class="subscription__variants">
      <template v-for="tariff in tariffsStandart">
        <TariffPromo v-if="tariff.promo" v-bind:tariff="tariff">
        </TariffPromo>
        <TariffOne v-if="!tariff.promo" v-bind:tariff="tariff">
        </TariffOne>
      </template>
      <router-link to="/tariffs-list-spb" v-if="status === 'new'"
        class="subscription-btn subscription__item subscription-btn_sbp-link">
        <img class="subscription-btn__image_sbp" src="/assets/icons/sbp.svg" />
        <div class="subscription-btn__name_sbp">Оплата через СБП</div>
      </router-link>
    </div>
    <div class="offer ">
      Оформляя подписку GoGiBey, вы принимаете условия
      <router-link class="offer__link" to="/documents">
        Договор оферты Согласие на подписку Услуги, стоимость и порядок оплаты
      </router-link> GoGIBey.
    </div>
  </div>
  <div class="modal modal_hide" id="modal"> <span class="modal__close-btn js-close-modal"></span>
    <div class="modal__icon tariff-change__icon"></div>
    <div class="modal__heading tariff-change-modal__heading"></div>
    <a class="button manage-sub__btn manage-sub__btn_gap js-tariff-change-confirm  button_blue ">Да, поменять</a>
    <a class="button manage-sub__btn js-manage-sub-btn-close  button_transparent"></a>
  </div>
  <div class="modal modal_hide" id="modal-standart">
    <div class="modal__elipsis"></div>
  </div>
  <div id="notification" class="notification notification_hide">
    <div class="notification__text js-notification-text"></div>
    <div class="notification__close-button js-close-notification">Скрыть</div>
  </div>
  <div class="blur-background">
  </div>
</template>

<script>

(function (m, e, t, r, i, k, a) {
  m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
  m[i].l = 1 * new Date();
  for (var j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return; } }
  k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(92920105, "init", {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true
});

import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
import { mapState, mapActions } from 'vuex';
import TariffOne from '@/components/profile/TariffOne.vue';
import TariffPromo from '@/components/profile/TariffPromo.vue';
import loadScript from '@/mixins/loadScript';
import payTinkoff from '@/mixins/payTinkoff';
export default {
  mixins: [tgTheme, tgBackButton, loadScript, payTinkoff],
  components: {
    TariffOne,
    TariffPromo,
  },
  async created() {
    await this.fetchTariffsStandart({
      status: this.status,
      tariffKey: this.tariff.key,
    });
  },
  computed: {
    ...mapState({
      userId: state => state.userData.id,
      status: state => state.userData.status,
      tariff: state => state.userData.tariff,
      tariffsStandart: state => state.allTariffs.tariffsStandart,
    }),
  },
  methods: {
    ...mapActions({
      fetchTariffsStandart: 'allTariffs/fetchTariffsStandart',
    }),
  },
  mounted() {
    Telegram.WebApp.expand();
    // this.loadScript('https://widget.cloudpayments.ru/bundles/cloudpayments.js');
    this.tgBackButtonVuex('/profile-menu');
    window.initSubscriptionVariant();
    window.initModal();
    window.initNotification();

    // была произведена оплата
    // в этом случае ссылка на этй страницу может выглядеть как /tariffs-list?payment=true&tariff=PRO
    const isPaymment = this.$route.query.payment;
    if (isPaymment === 'true') {
      const tarifTitle = this.$route.query.tariff;
      window.showSuccess({
        title: 'Подписка успешно оформлена',
        description: `Тариф: ${tarifTitle}`,
        buttonOkText: 'Отлично',
        canClose: true,
        onClose: () => {
          // window.location.href = '#/';
          this.$router.push('/');
        },
      });
    }
    if (isPaymment === 'false') {
      window.showError({
        canClose: true,
        onClose: () => {
        },
        onRepeat: () => {
          this.payTinkoff(this.userId, this.$route.query.tariff);
        },
      });
    }
  },
}
</script>
