import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;

export default {
  state: () => ({
    isAuth: null,
    id: 0,
    firstName: '',
    lastName: '',
    photoUrl: '',
    email: '',
    status: '',
    paymentSystem: '',
    tariff: {}, // объект из класса Tariff
    queryId: '',
    servicesMovie: {},
  }),

  getters: {
    fullName(state) {
      return `${state.firstName} ${state.lastName}`;
    },
    endTarif(state) {
      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      const date = new Date(state.tariff.end);
      return `${date.getDate()} ${months[date.getMonth()]}`;
    },
    nextPrice(state) {
      return state.tariff.next ? state.tariff.next.price : state.tariff.price;
    },
  },

  mutations: {
    setIsAuth(state, isAuth) {
      state.isAuth = isAuth;
    },
    setId(state, id) {
      state.id = id;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setPhotoUrl(state, photoUrl) {
      state.photoUrl = photoUrl;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setPaymentSystem(state, paymentSystem) {
      state.paymentSystem = paymentSystem;
    },
    setTariff(state, tariff) {
      state.tariff = tariff;
    },
    setQueryId(state, queryId) {
      state.queryId = queryId;
    },
    setServiceMovie(state, servcie) {
      state.servicesMovie[servcie.name] = servcie.value;
    },
    setServicesMovie(state, servcies) {
      state.servicesMovie = servcies;
    },
  },

  actions: {
    async fetchUserData(context, tg) {

      // work in telegram
      const checkHash = (await axios(serviceApi, {
        params: {
          target: 'check-hash-api6',
          string: tg.initData,
          initDataUnsafe: tg.initDataUnsafe,
          browserVersion: navigator.userAgent,
          currentUrl: location.href,
        }
      })).data;
      // end work in telegram

      // test only for browser
      // const checkHash = true;
      // tg = {
      //   initDataUnsafe: {
      //     user: {
      //       id: 111112064,
      //       query_id: 123,
      //       first_name: 'Stanislav',
      //       last_name: 'Stanislav',
      //     }
      //   }
      // }
      // end test

      if (checkHash === true) {
        context.commit('setId', tg.initDataUnsafe.user.id);
        context.commit('setFirstName', tg.initDataUnsafe.user.first_name);
        context.commit('setLastName', tg.initDataUnsafe.user.last_name);
        context.commit('setQueryId', tg.initDataUnsafe.query_id);
        const userData = (await axios(serviceApi, {
          params: {
            target: 'get-user-data',
            userId: tg.initDataUnsafe.user.id,
          }
        })).data;
        if (userData.photoUrl) context.commit('setPhotoUrl', userData.photoUrl);
        context.commit('setEmail', userData.email);
        context.commit('setStatus', userData.status);
        context.commit('setPaymentSystem', userData.paymentSystem);
        context.commit('setTariff', userData.tariff);
        context.commit('setServicesMovie', userData.servicesMovie);
        context.commit('setIsAuth', true);
      } else {
        context.commit('setIsAuth', false);
      }
    },

    // сообщение бекенду об отмене подписки
    async removeSubscription(context, id) {
      const data = (await axios(serviceApi, {
        params: {
          target: 'remove-subscription',
          userId: id,
          newStatus: 'old',
        }
      })).data;
      if (data.success) {
        context.commit('setStatus', 'old');
      }
      return data.success;
    },

    // сохранить email
    async postEmail(context) {
      const data = (await axios(serviceApi, {
        params: {
          target: 'post-email',
          userId: context.state.id,
          email: context.state.email,
        }
      })).data;
      if (data.success) {
        context.commit('setEmail', context.state.email);
      }
      return data.success;
    },

    // сохранить изменениия в сервисах фильмов (таблица user, поле service_movie)
    async postServiceMovie(context) {
      const data = (await axios(serviceApi, {
        params: {
          target: 'post-service-movie',
          userId: context.state.id,
          servicesMovie: context.state.servicesMovie,
        }
      })).data;
      return data.success;
    }

  },

  namespaced: true,
}
