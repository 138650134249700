<template>
  <div class="ta-actor-page">
    <div class="ta-actor-card">
      <div class="ta-actor-card__circles"></div>

      <div class="ta-actor-card__img">
        <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=1000" alt="">
      </div>

      <a href="#0" class="ta-actor-card__btn"></a>

      <div class="ta-actor-card__desc">
        <h1 class="ta-actor-card__desc-title">Гаспар Ульель</h1>
        <ul class="ta-actor-card__desc-list">
          <li>
            <span>Родился:</span>
            25 ноября 1984 г., Булонь-Бийанкур, О-де-Сен, Франция
          </li>
          <li>
            <span>Умер:</span>
            19 января 2022 г. (37 лет), Изер, Fransa
          </li>
        </ul>
        <p class="ta-actor-card__desc-text">Французский актёр кино и театра, модель.
          Международную известность приобрёл после
          исполнения одной из главных ролей в
          детективной драме Жан-Пьера Жёне
          «Долгая помолвка».
        </p>
      </div>
    </div>

    <div class="ta-sm-grid">
      <h2 class="ta-sm-grid__title">Снимался</h2>
      <div class="ta-sm-grid__list">
        <apps-movies-grid />
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsMoviesGrid from '@/components/apps/AppsMoviesGrid.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsMoviesGrid,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
