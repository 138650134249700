<template>
  <div class="ta-skill-page">
    <div class="ta-skill-page__body">
      <h1 class="ta-skill-page__title">Выберите свой skil</h1>
      <AppsSkillGrid />
      <div class="ta-skill-page__skill-card">
        <AppsSkillCard />
        <div class="ta-skill-info ta-skill-page__info">
          <h2 class="ta-skill-info__title">Информация</h2>
          <ul class="ta-skill-info__list">
            <li class="ta-skill-info__item">
              <a href="#0" class="ta-skill-info__link">
                Веб-сайт
                <svg width="17" height="16">
                  <use xlink:href="/assets/images/sprite.svg#skill-web-icon" />
                </svg>
              </a>
            </li>
            <li class="ta-skill-info__item">
              <a href="#0" class="ta-skill-info__link">
                Политика конфиденциальности
                <svg width="17" height="16">
                  <use xlink:href="/assets/images/sprite.svg#info-hand-icon" />
                </svg>
              </a>
            </li>
            <li class="ta-skill-info__item">
              <a href="#0" class="ta-skill-info__link">
                Публичная оферта
                <svg width="16" height="17">
                  <use xlink:href="/assets/images/sprite.svg#info-list-icon" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ta-skill-page__footer">
      <div class="ta-bottomsheet">
        <button class="ta-button">
          Добавить Skil
          <img src="/assets/images/appIcon.svg" alt="" class="ta-button__icon">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsSkillGrid from '@/components/apps/AppsSkillGrid.vue';
  import AppsSkillCard from '@/components/apps/AppsSkillCard.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsSkillGrid,
      AppsSkillCard,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
