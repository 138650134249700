<template>
  <div class="center history">
    <header class="history__header">История</header>
    <div class="history__list">
      <template v-for="item in history">
        <template v-if="item.typeAnswer === 'no'">
          <HistoryElementNo v-bind:request="item.request">
          </HistoryElementNo>
        </template>
        <template v-if="item.typeAnswer === 'single'">
          <HistoryElementSingle v-bind:request="item.request" v-bind:image="item.image">
          </HistoryElementSingle>
        </template>
        <template v-if="item.typeAnswer === 'multi'">
          <HistoryElementMulti v-bind:request="item.request">
          </HistoryElementMulti>
        </template>
      </template>
      <!-- <div class="history-card history_gap ">
        <span class="history-card__icon "></span>
        <div class="history-card__text ">фильм про синих людей как инопланетяне</div>
        <div class="history-card__image" style="background-image: url(/assets/images/avatar.jpg)"></div>
      </div> -->
      <!-- <div class="history-card history_gap  history-card_red ">
        <span class="history-card__icon  history-card__icon_red "></span>
        <div class="history-card__text  history-card__text_red ">незнаю что как то что</div>
      </div> -->
      <!-- <div class="history-card history_gap ">
        <span class="history-card__icon "></span>
        <div class="history-card__text ">фильм про синих людей как инопланетяне</div>
        <div class="history-card__placeholder">
          <div class="history-card__placeholder_icon"
            style="-webkit-mask-image: url(/assets/icons/noimg.svg); mask-image: url(/assets/icons/noimg.svg)"></div>
        </div>
      </div> -->
      <!-- остался не используемый компонет, Руслан не знает, что с ним делать
        <div class="history-card history_gap ">
        <span class="history-card__icon "></span>
        <div class="history-card__text ">фильм про синих людей как инопланетяне</div>
        <div class="history-card__placeholder">
          <div class="history-card__placeholder_icon"
            style="-webkit-mask-image: url(/assets/icons/cross.svg); mask-image: url(/assets/icons/cross.svg)"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import HistoryElementSingle from '@/components/profile/HistoryElementSingle.vue';
import HistoryElementNo from '@/components/profile/HistoryElementNo.vue';
import HistoryElementMulti from '@/components/profile/HistoryElementMulti.vue';
import { mapState, mapActions } from 'vuex';
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
export default {
  mixins: [tgTheme, tgBackButton],
  components: {
    HistoryElementSingle,
    HistoryElementNo,
    HistoryElementMulti,
  },
  created() {
    this.fetchUserHistory(this.id);
  },
  computed: {
    ...mapState({
      history: state => state.userHistory.history,
      id: state => state.userData.id,
    }),
  },
  methods: {
    ...mapActions({
      fetchUserHistory: 'userHistory/fetchUserHistory',
    }),
  },
  mounted() {
    this.tgBackButtonVuex('/profile-menu');
  },
}
</script>
