import { createApp } from 'vue';
import App from '@/Main.vue';
import store from '@/store';
import router from '@/routers';

const app = createApp(App);

app
  .use(router)
  .use(store)
  .mount('#app')
