<template>
  <div class="center profile-edit">
    <header class="profile-edit__header">Профиль</header>
    <img v-if="photoUrl" v-bind:src="photoUrl" alt="Аватар" class="profile-edit__avatar" />
    <img v-if="photoUrl === '' && theme === 'light'" src="/assets/icons/no-photo-white.png" alt="Аватар"
      class="profile-edit__avatar" />
    <img v-if="photoUrl === '' && theme === 'dark'" src="/assets/icons/no-photo-black.png" alt="Аватар"
      class="profile-edit__avatar" />
    <form action="" id="user-form" class="profile-edit__form user-form">
      <input type="text" name="name" class="form-el user-form__name" disabled="disabled" v-bind:value="fullName" />
      <div class="button-in">
        <input type="text" name="id" id="profile-edit__id" class="form-el user-form__id" disabled="disabled"
          v-bind:value="id" />
        <button type="button" id="profile-edit__copy-btn" class="user-form__copy-btn"></button>
      </div>
      <input v-model="email" type="text" name="email" id="profile-edit__email" class="form-el user-form__email"
        placeholder="Почта" />
      <input type="submit" id="profile-edit__submit" class="form-el user-form__submit" value="Сохранить" />
    </form>
    <div class="iphone-gap js-iphone-gap"></div>
  </div>
  <div id="notification" class="notification notification_hide">
    <div class="notification__text js-notification-text"></div>
    <div class="notification__close-button js-close-notification">Скрыть</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
export default {
  mixins: [tgTheme, tgBackButton],
  computed: {
    ...mapState({
      photoUrl: state => state.userData.photoUrl,
      status: state => state.userData.status,
      id: state => state.userData.id,
    }),
    ...mapGetters({
      fullName: 'userData/fullName',
    }),
    email: {
      get() {
        return this.$store.state.userData.email;
      },
      set(value) {
        this.$store.commit('userData/setEmail', value);
      }
    },
  },
  methods: {
    ...mapActions({
      postEmail: 'userData/postEmail',
    }),
  },
  mounted() {
    this.tgBackButtonVuex('/profile-menu');
    window.editProfile.init({ email: this.email });
    window.initNotification();
    window.editProfile.onSubmit = async (data) => {
      const email = data.email;
      if (validateEmail(email)) {
        const success = await this.postEmail();
        if (success) window.openNotification('Почта успешно сохранена', 'blue', 2000);
        else window.openNotification('Ошибка при сохранении почты', 'orange', 2000);
      } else {
        window.openNotification('Неверный формат email', 'orange', 2000);
      }
    }
    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
  },
}
</script>
