<template>
  <div class="ta-skill-grid">
    <label class="ta-skill-grid__item ta-skill-grid__item--image">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <img src="/assets/images/appIcon.svg" alt="">
        </div>
      </div>
      <h3 class="ta-skill-grid__title">Поиск фильмов</h3>
    </label>
    <label class="ta-skill-grid__item ta-skill-grid__item--web">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <svg width="42" height="41">
            <use xlink:href="/assets/images/sprite.svg#skill-web-icon" />
          </svg>
        </div>
      </div>
      <h3 class="ta-skill-grid__title">Сайт</h3>
    </label>
    <label class="ta-skill-grid__item ta-skill-grid__item--ya">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <svg width="42" height="41">
            <use xlink:href="/assets/images/sprite.svg#skill-ya-icon" />
          </svg>
        </div>
      </div>
      <h3 class="ta-skill-grid__title">Выдача Яндекс</h3>
    </label>
    <label class="ta-skill-grid__item ta-skill-grid__item--user">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <svg width="40" height="39">
            <use xlink:href="/assets/images/sprite.svg#skill-user-icon" />
          </svg>
        </div>
      </div>
      <h3 class="ta-skill-grid__title">Личная страничка</h3>
    </label>
    <label class="ta-skill-grid__item ta-skill-grid__item--zara">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <svg width="52" height="22">
            <use xlink:href="/assets/images/sprite.svg#skill-zara-icon" />
          </svg>
        </div>
      </div>
      <h3 class="ta-skill-grid__title">ZARA</h3>
    </label>
    <label class="ta-skill-grid__item ta-skill-grid__item--support">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <svg width="38" height="38">
            <use xlink:href="/assets/images/sprite.svg#skill-support-icon" />
          </svg>
        </div>
      </div>
      <h3 class="ta-skill-grid__title">Поддежка GoGiBey</h3>
    </label>
    <label class="ta-skill-grid__item ta-skill-grid__item--search">
      <input type="checkbox" />
      <div class="ta-skill-grid__img-wrapper">
        <div class="ta-skill-grid__img">
          <svg width="34" height="34">
            <use xlink:href="/assets/images/sprite.svg#skill-search-icon" />
          </svg>
        </div>
      </div>
      <h3 class="ta-skill-grid__title">Поиск фильмов</h3>
    </label>
  </div>
</template>

<script>
  export default {}
</script>
