<template>
  <swiper
    slides-per-view="auto"
    :space-between="6"
    :free-mode="true"
    :autoHeight="true"
  >
    <swiper-slide v-for="actor in actors">
      <a href="#0" class="ta-sm-actors__card">
        <div class="ta-sm-actors__card-img">
          <img :src="actor.img" alt="">
        </div>
        <h3 class="ta-sm-actors__card-title">{{ actor.title }}</h3>
        <p class="ta-sm-actors__card-hint">{{ actor.hint }}</p>
      </a>
    </swiper-slide>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data: () => ({
      actors: [
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Оскар Айзек', hint: 'Marc Spector' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Итан Хоук', hint: 'Arthur Harrow' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Мэй Каламави', hint: 'Layla El-Faouly' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Гаспар Ульель', hint: 'Anton Mogart' },
        { img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300', title: 'Мэй Каламави', hint: 'Layla El-Faouly' },
      ]
    }),
  }
</script>
