<template>
  <div class="loading-page">
    <div class="sm-loading">
      <div class="sm-loading__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24">
          <path class="sm-loading__icon-bg"
            d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z" />
          <path class="sm-loading__icon-fill" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
            <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite" to="360 12 12"
              type="rotate" />
          </path>
        </svg>
      </div>
      <div class="sm-loading__text">загрузка</div>
    </div>
  </div>
  <link rel="preload" href="./style.css" />
  <link href="/assets/fonts/INTER/Inter-Regular.woff2" as="font" type="font/woff2" crossorigin>
  <link href="/assets/fonts/SF-PRO-TEXT/SFProText-Regular.woff2" as="font" type="font/woff2" crossorigin>
  <link href="/assets/fonts/SF-PRO-TEXT/SFProText-Semibold.woff2" as="font" type="font/woff2" crossorigin>
  <link href="/assets/fonts/INTER/Inter-Regular.woff2" as="font" type="font/woff2" crossorigin>
  <link href="/assets/fonts/SF-PRO-DISPLAY/SFProDisplay-Bold.woff2" as="font" type="font/woff2" crossorigin>
  <link href="/assets/fonts/SF-PRO-TEXT/SFProText-Medium.woff2" as="font" type="font/woff2" crossorigin>
</template>

<script>
document.fonts.onloading = () => {
  console.log('fonts.onloading');
};
</script>