<template>
  <div class="ta-skill-slider">
    <swiper
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :autoHeight="true"
    >
      <swiper-slide v-for="slide in slides">
        <div class="ta-skill-slider__img">
          <img :src="slide.img" alt="">
        </div>
        <div class="ta-skill-slider__desc">
          <div class="ta-skill-slider__icon">
            <svg width="34" height="34">
              <use :xlink:href="`/assets/images/sprite.svg#${slide.icon}`" />
            </svg>
          </div>
          <div class="ta-skill-slider__wrapper">
            <h3 class="ta-skill-slider__title">{{ slide.title }}</h3>
            <p class="ta-skill-slider__text">{{ slide.text }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination } from 'swiper';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Pagination],
      };
    },
    data: () => ({
      slides: [
        {
          img: '/assets/images/slide-1.jpg',
          icon: 'action-search-icon',
          title: 'Поиск по скрину',
          text: 'Делись скрином с  GoGiBey  и узнай название фильма',
        },
        {
          img: '/assets/images/slide-2.jpg',
          icon: 'action-search-icon',
          title: 'Поиск по скрину',
          text: 'Делись скрином с  GoGiBey  и узнай название фильма',
        },
        {
          img: '/assets/images/slide-1.jpg',
          icon: 'action-search-icon',
          title: 'Поиск по скрину',
          text: 'Делись скрином с  GoGiBey  и узнай название фильма',
        },
        {
          img: '/assets/images/slide-2.jpg',
          icon: 'action-search-icon',
          title: 'Поиск по скрину',
          text: 'Делись скрином с  GoGiBey  и узнай название фильма',
        }
      ]
    }),
  }
</script>
