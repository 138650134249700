<template>
  <div class="ta-skill-page">
    <div class="ta-skill-page__body">
      <h1 class="ta-skill-page__title">Выберите свой skil</h1>
      <AppsSkillGrid />
    </div>
    <div class="ta-skill-page__footer">
      <div class="ta-bottomsheet">
        <button class="ta-button">
          Добавить Skil
          <img src="/assets/images/appIcon.svg" alt="" class="ta-button__icon">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';
  import AppsSkillGrid from '@/components/apps/AppsSkillGrid.vue';

  export default {
    mixins: [tgTheme],
    components: {
      AppsSkillGrid,
    },
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
