<template>
  <div v-show="isMovieLoad" class="ta-movie-list__item">
    <div class="ta-movie-list__item-pic">
      <div class="ta-movie-list__item-num">
        <span class="ta-movie-list__item-star">
          <svg width="8" height="7">
            <use xlink:href="images/sprite.svg#star" />
          </svg>
        </span>
        <b>{{ index + 1 }}</b>
      </div>
      <div class="ta-movie-list__item-img">
        <img :src="posterUrl" alt="" v-on:load="loadImage">
      </div>
    </div>
    <div class="ta-movie-list__item-desc">
      <h3 class="ta-movie-list__item-title">{{ movie.title }}</h3>
      <div class="ta-movie-list__item-text">{{ movie.countries }}</div>
    </div>
    <button v-on:click="сlickMovieInChart" class="ta-movie-list__button">Открыть</button>
  </div>
</template>
<script>
import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
const urlMedia = process.env.VUE_APP_URL_MEDIA;
import { mapState, mapMutations } from 'vuex';
export default {
  data() {
    return {
      isMovieLoad: false,
    }
  },
  props: {
    movie: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      userId: state => state.userData.id,
      counterMovies: state => state.chartGogi.counterMovies,
    }),
    posterUrl() {
      let result;
      if (!this.movie.posterUrl) {
        if (this.theme === 'light') result = '/assets/icons/no-icon-chart-white.png';
        else result = '/assets/icons/no-icon-chart-black.png';
      }
      else result = `${urlMedia}${this.movie.posterUrl}`;
      return result;
    },
  },
  methods: {
    ...mapMutations({
      setCounterMovies: 'chartGogi/setCounterMovies',
    }),
    loadImage() {
      this.isMovieLoad = true;
      this.setCounterMovies(this.counterMovies+1);
    },
    сlickMovieInChart() {
      axios(serviceApi, {
        params: {
          target: 'show-movie-from-chart',
          kinopoiskId: this.movie.kinopoiskId,
          userId: this.userId,
        },
      });
      Telegram.WebApp.close();
    }
  },
}
</script>
