<template>
  <div class="ta-search-page">
    <div class="ta-search-page__hint">
      5 результатов по запросу <b>«Лучшие фильмы»</b>
    </div>
    <div class="ta-search-results-grid">
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark active"></button>
          <button class="ta-search-results-grid__card-visible active"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
      <div class="ta-search-results-grid__item">
        <div class="ta-search-results-grid__card">
          <button class="ta-search-results-grid__card-bookmark"></button>
          <button class="ta-search-results-grid__card-visible"></button>
          <div class="ta-search-results-grid__card-rating">8,3</div>
          <div class="ta-search-results-grid__card-img">
            <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=500" alt="">
          </div>
          <h3 class="ta-search-results-grid__card-title">Лунный рыцарь</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tgTheme from '@/mixins/tgTheme';

  export default {
    mixins: [tgTheme],
    mounted() {
      document.querySelector('html').classList.add('ta-app');
    },
    destroyed() {
      document.querySelector('html').classList.remove('ta-app');
    },
  }
</script>
