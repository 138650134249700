<template>
  <div class="ta-main-page">
    <div class="ta-main-page__header">
      <div class="ta-main-page__header-label">skilMay</div>
    </div>
    <div class="ta-main-page__grid">
      <div class="ta-skill-grid">
        <ProfileIcon 
          v-bind:photoUrl="photoUrl" 
          v-bind:isAuth="isAuth" 
          v-bind:theme="theme">
        </ProfileIcon>
        <ApplicationIcon 
          v-bind:countAlerts="0" 
          v-bind:url="'/settings'" 
          v-bind:icon="'/assets/images/appIcon.svg'" 
          v-bind:title="'Поиск фильмов'">
        </ApplicationIcon>
        <ApplicationIcon 
          v-bind:countAlerts="0" 
          v-bind:url="'/help'" 
          v-bind:icon="'/assets/images/helpIcon.svg'" 
          v-bind:title="'Help'"
          v-bind:userId="userId">
        </ApplicationIcon>
      </div>
    </div>

    <div class="ta-main-page__movies">
      <div class="ta-movie-list">
        <div class="ta-movie-list__header">
          <div class="ta-movie-list__header-left">
            <div class="ta-movie-list__header-hint">GoGIBey чарт</div>
            <div class="ta-movie-list__header-title">Ищут сейчас</div>
          </div>
          <div class="ta-movie-list__header-right">
            <div class="ta-sm-loading" v-bind:class="{ 'ta-sm-loading--hide': !isShowFirstChartSpinner }">
              <div class="ta-sm-loading__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24">
                  <path class="ta-sm-loading__icon-bg"
                    d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z" />
                  <path class="ta-sm-loading__icon-fill" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                    <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite"
                      to="360 12 12" type="rotate" />
                  </path>
                </svg>
              </div>
              <div class="ta-sm-loading__text">Загрузка</div>
            </div>
          </div>
        </div>
        <div id="chart-first" class="ta-movie-list__body">
          <AppsChartList v-bind:theme="theme" />
        </div>
        <div class="ta-movie-list__footer">
          <button v-show="isShowNextChartButton" v-on:click="loadNextChartPortion" class="ta-more-btn"
            v-bind:class="{ 'ta-more-btn--loading': isShowNextChartSpinner }">
            еще
            <span class="ta-more-btn__arrow">
              <svg width="17" height="10">
                <use xlink:href="/assets/images/sprite.svg#arrow-down" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="blur-background">
  </div>

  <div id="notification" class="notification notification_hide">
    <div class="notification__text js-notification-text"></div>
    <div class="notification__close-button js-close-notification">Скрыть</div>
  </div>

  <div class="popover popover_hide">
    <div class="popover__rating">
    </div>
    <div class="popover-movie popover__movie">
      <div class="popover-movie__header">
        <header class="popover-movie__title"></header>
        <span class="popover-movie__year"></span>
      </div>
      <div class="popover-movie__info"><span class="popover-movie__country"></span><span
          class="popover-movie__divider"></span><span class="popover-movie__duration"></span></div>
      <div class="popover-movie__genre"></div>
      <div class="popover-movie__actors">
        <div class="actor popover-movie__actors_gap">
          <div class="actor__image"></div>
          <div class="actor__name"></div>
          <div class="actor__character"></div>
        </div>
        <div class="actor popover-movie__actors_gap">
          <div class="actor__image"></div>
          <div class="actor__name"></div>
          <div class="actor__character"></div>
        </div>
        <div class="actor popover-movie__actors_gap">
          <div class="actor__image"></div>
          <div class="actor__name"></div>
          <div class="actor__character"></div>
        </div>
        <div class="actor popover-movie__actors_gap">
          <div class="actor__image"></div>
          <div class="actor__name"></div>
          <div class="actor__character"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationIcon from '@/components/apps/ApplicationIcon.vue';
import ProfileIcon from '@/components/apps/ProfileIcon.vue';
import AppsChartList from '@/components/apps/AppsChartList.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import tgTheme from '@/mixins/tgTheme';

export default {
  mixins: [tgTheme],
  components: {
    ApplicationIcon,
    ProfileIcon,
    AppsChartList,
  },
  computed: {
    ...mapState({
      isAuth: state => state.userData.isAuth,
      photoUrl: state => state.userData.photoUrl,
      userId: state => state.userData.id,
      isLoaded: state => state.chartGogi.isLoaded,
      isNextPortionLoaded: state => state.chartGogi.isNextPortionLoaded,
      chartCounterMovies: state => state.chartGogi.counterMovies,
      chartLimitMovies: state => state.chartGogi.limitMovies,
    }),
    isShowFirstChartSpinner() {
      return this.chartCounterMovies !== this.chartLimitMovies;
    },
    isShowNextChartButton() {
      return (this.isLoaded && !this.isShowFirstChartSpinner) &&
        !this.isNextPortionLoaded || this.isShowNextChartSpinner;
    },
    isShowNextChartSpinner() {
      return this.isNextPortionLoaded && this.chartCounterMovies !== this.chartLimitMovies;
    },
  },
  methods: {
    ...mapActions({
      fetchChartPortion: 'chartGogi/fetchChartPortion',
    }),
    ...mapMutations({
      setIsNextPortionLoaded: 'chartGogi/setIsNextPortionLoaded',
    }),
    async loadNextChartPortion() {
      await this.fetchChartPortion();
      this.setIsNextPortionLoaded(true);
    }
  },
  mounted() {
    Telegram.WebApp.expand();
    Telegram.WebApp.BackButton.hide();
    document.querySelector('html').classList.add('ta-app');
    // window.baseConfig = {
    //   animationUpScale: true,
    //   animationUpScaleTime: '800ms',
    //   animationDownScale: true,
    //   animationDownScaleTime: '800ms',
    // };
  },
  destroyed() {
    document.querySelector('html').classList.remove('ta-app');
  },
}
</script>
