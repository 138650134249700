<template>
  <div v-if="isAuth === true">
    <router-view></router-view>
  </div>
  <div v-if="isAuth === false">
    <div class="open-movie center" id="manage-sub">
      <div class="open-movie-wrap">
        <div class="open-movie__container">
          <header class="open-movie__title">
            Мы не смогли авторизовать вас
          </header>
          <div class="open-movie__info">
            <div class="open-movie__variant">
              Перейдите в бот GoGiBey или напишите в поддержку
            </div>
          </div>
          <a href="https://t.me/poisk_films_support" class="button open-movie__btn open-movie__btn_gap  button_blue ">
            Написать в поддержку
          </a>
          <!-- <a href="https://t.me/GoGiBeybot" class="button open-movieb__btn  button_transparent">
            Перейти в GoGiBey
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  async created() {
    if (!this.isAuth) {
      this.fetchUserData({
        initData: Telegram.WebApp.initData,
        initDataUnsafe: Telegram.WebApp.initDataUnsafe,
      });
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.userData.isAuth,
    }),
  },
  methods: {
    ...mapActions({
      fetchUserData: 'userData/fetchUserData',
    }),
  },
  mounted() {
    // Telegram.WebApp.enableClosingConfirmation();
    Telegram.WebApp.ready();
  },
}
</script>
