import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
export default {
  state: () => ({
    movies: [],
    isLoaded: false,
    isNextPortionLoaded: false,
    limitMovies: 5,
    offsetMovies: 0,
    counterMovies: 0,
  }),
  mutations: {
    addMovies(state, newPortion) {
      state.movies = [...state.movies, ...newPortion];
    },
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setIsNextPortionLoaded(state, isNextPortionLoaded) {
      state.isNextPortionLoaded = isNextPortionLoaded;
    },
    setOffsetMovies(state, offsetMovies) {
      state.offsetMovies = offsetMovies;
    },
    setCounterMovies(state, counterMovies) {
      state.counterMovies = counterMovies;
    },
  },
  actions: {
    async fetchChartPortion(context) {
      const newPortion = (await axios(serviceApi, {
        params: {
          target: 'get-chart-portion',
          limit: context.state.limitMovies,
          offset: context.state.offsetMovies,
        }
      })).data;
      if (newPortion) {
        context.commit('addMovies', newPortion);
        context.commit('setIsLoaded', true);
        context.commit('setCounterMovies', 0);
        context.commit('setOffsetMovies', context.state.offsetMovies + context.state.limitMovies);
      }
    },
  },

  namespaced: true,
}
