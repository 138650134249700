import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
export default {
  state: () => ({
    tariffsStandart: [],
    tariffsSbp: [],
  }),
  mutations: {
    setTariffsStandart(state, tariffsStandart) {
      state.tariffsStandart = tariffsStandart;
    },
    setTariffsSbp(state, tariffsSbp) {
      state.tariffsSbp = tariffsSbp;
    },
  },
  actions: {
    async fetchTariffsStandart(context, params) {
      const tariffsStandart = (await axios(serviceApi, {
        params: {
          target: 'get-tariffs-standart',
          status: params.status,
          tariffKey: params.tariffKey,
        }
      })).data;
      context.commit('setTariffsStandart', tariffsStandart);
    },
    async fetchTariffsSbp(context) {
      const tariffsSbp = (await axios(serviceApi, {
        params: {
          target: 'get-tariffs-sbp',
        }
      })).data;
      context.commit('setTariffsSbp', tariffsSbp);
    },
  },
  namespaced: true,
}
