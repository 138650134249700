import {createRouter, createWebHashHistory} from 'vue-router';
import OpenMovie from '@/pages/OpenMovie.vue';
import Loading from '@/pages/Loading.vue';
import Apps from '@/pages/Apps.vue';
import ProfileMenu from '@/pages/ProfileMenu.vue';
import ProfileEdit from '@/pages/ProfileEdit.vue';
import TariffsList from '@/pages/TariffsList.vue';
import TariffInfo from '@/pages/TariffInfo.vue';
import TatiffsListSbp from '@/pages/TatiffsListSbp.vue';
import History from '@/pages/History.vue';
import Favorites from '@/pages/Favorites.vue';
import Documents from '@/pages/Documents.vue';
import ReceiptsList from '@/pages/ReceiptsList.vue';
import Settings from '@/pages/Settings.vue';

import TaActor from '@/pages/new/Actor.vue';
import TaChart from '@/pages/new/Chart.vue';
import TaMovie from '@/pages/new/Movie.vue';
import TaLoading from '@/pages/new/Loading.vue';
import TaTransfer from '@/pages/new/Transfer.vue';
import TaSearchDesc from '@/pages/new/SearchDesc.vue';
import TaSearchSelect from '@/pages/new/SearchSelect.vue';
import TaSearchScreen from '@/pages/new/SearchScreen.vue';
import TaSearchGenre from '@/pages/new/SearchGenre.vue';
import TaSearchGenre2 from '@/pages/new/SearchGenre2.vue';
import TaSearchGenre3 from '@/pages/new/SearchGenre3.vue';
import TaSkill from '@/pages/new/Skill.vue';
import TaSkillAddLoading from '@/pages/new/SkillAddLoading.vue';
import TaSkillAddSuccess from '@/pages/new/SkillAddSuccess.vue';
import TaSkillAdd from '@/pages/new/SkillAdd.vue';
import TaSkillCard from '@/pages/new/SkillCard.vue';
import TaSkillLoading from '@/pages/new/SkillLoading.vue';
import TaSkillLoading2 from '@/pages/new/SkillLoading2.vue';
import TaSkillSlider from '@/pages/new/SkillSlider.vue';


const routes = [
  {
    path: '/open-movie',
    component: OpenMovie,
  },
  {
    path: '/loading',
    component: Loading,
  },
  {
    path: '/',
    component: Apps,
  },
  {
    path: '/profile-edit',
    component: ProfileEdit,
  },
  {
    path: '/profile-menu',
    component: ProfileMenu,
  },
  {
    path: '/tariffs-list',
    component: TariffsList,
  },
  {
    path: '/tariff-info',
    component: TariffInfo,
  },
  {
    path: '/tariffs-list-spb',
    component: TatiffsListSbp,
  },
  {
    path: '/history',
    component: History,
  },
  {
    path: '/favorites',
    component: Favorites,
  },
  {
    path: '/documents',
    component: Documents,
  },
  {
    path: '/receipts',
    component: ReceiptsList,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/new/actor',
    component: TaActor,
  },
  {
    path: '/new/chart',
    component: TaChart,
  },
  {
    path: '/new/movie',
    component: TaMovie,
  },
  {
    path: '/new/loading',
    component: TaLoading,
  },
  {
    path: '/new/transfer',
    component: TaTransfer,
  },
  {
    path: '/new/search-desc',
    component: TaSearchDesc,
  },
  {
    path: '/new/search-select',
    component: TaSearchSelect,
  },
  {
    path: '/new/search-screen',
    component: TaSearchScreen,
  },
  {
    path: '/new/search-genre',
    component: TaSearchGenre,
  },
  {
    path: '/new/search-genre-2',
    component: TaSearchGenre2,
  },
  {
    path: '/new/search-genre-3',
    component: TaSearchGenre3,
  },
  {
    path: '/new/skill',
    component: TaSkill,
  },
  {
    path: '/new/skill-add-loading',
    component: TaSkillAddLoading,
  },
  {
    path: '/new/skill-add-success',
    component: TaSkillAddSuccess,
  },
  {
    path: '/new/skill-add',
    component: TaSkillAdd,
  },
  {
    path: '/new/skill-card',
    component: TaSkillCard,
  },
  {
    path: '/new/skill-loading',
    component: TaSkillLoading,
  },
  {
    path: '/new/skill-loading-2',
    component: TaSkillLoading2,
  },
  {
    path: '/new/skill-slider',
    component: TaSkillSlider,
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

export default router;
