<template>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      taskId: this.$route.query.taskId,
      urlGroupOpenTask: process.env.VUE_APP_OPEN_TASK_BOT,
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.userData.isAuth,
      status: state => state.userData.status,
    }),
  },
  mounted() {
    if (this.isAuth) {
      if (this.status === 'active') {
        Telegram.WebApp.openTelegramLink(`${this.urlGroupOpenTask}?start=${this.taskId}`);
      } else {
        this.$router.replace('/tariffs-list');
      }
    }
  }
}
</script>
