<template>
  <a v-on:click="open" class="ta-skill-grid__item ta-skill-grid__item--image">
    <div class="ta-skill-grid__img-wrapper">
      <div class="ta-skill-grid__img ta-skill-grid__img--image">
        <img v-bind:src="icon" alt="">
      </div>
      <div v-if="countAlerts > 0" class="ta-skill-grid__img-count">{{ countAlerts }}</div>
    </div>
    <h3 class="ta-skill-grid__title">{{ title }}</h3>
  </a>
</template>

<script>
import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
export default {
  props: {
    countAlerts: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    open() {
      if (this.url === '/settings') {
        Telegram.WebApp.HapticFeedback.selectionChanged();
        this.$router.push(this.url);
      }
      if (this.url === '/help') {
        axios(serviceApi, {
          params: {
            target: 'get-help-message',
            userId: this.userId,
          },
        });
        Telegram.WebApp.close();
      }
    },
  },
}
</script>

