/* Поддержка обработки результатов оформления подписки
 * В случае успешеной или не успешной подписки выставляестся соответствующий флаг
 * При дальнейшем переходе на страницу с меню, показывкаем соотвеетствующий пуш
 */
export default {
  state: () => ({
    // флаг, true если подписку оформили успешно
    isSucces: false,
    // флаг, true если подписку оформили НЕ успешно
    isFail: false,
  }),
  mutations: {
    invertSucces(state) {
      state.isSucces = !state.isSucces;
    },
    invertFail(state) {
      state.isFail = !state.isFail;
    },
  },
  namespaced: true,
}
