<template>
  <div class="bills center" id="bills-page">
    <div class="bills center">
      <header class="bills__header">Чеки</header>
      <div class="bills__list">
        <template v-for="receipt in receipts">
          <ReceiptOne v-bind:receipt="receipt"></ReceiptOne>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const serviceApi = process.env.VUE_APP_URL_API;
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
import { mapState } from 'vuex';
import ReceiptOne from '@/components/profile/ReceiptOne.vue';
export default {
  mixins: [tgTheme, tgBackButton],
  components: {
    ReceiptOne,
  },
  data() {
    return {
      receipts: [],
    }
  },
  async created() {
    const data = (await axios(serviceApi, {
      params: {
        target: 'fetch-receipts',
        userId: this.userId,
      }
    })).data;
    this.receipts = data;
  },
  computed: {
    ...mapState({
      userId: state => state.userData.id,
    }),
  },
  mounted() {
    this.tgBackButtonVuex('/profile-menu');
  }
}
</script>