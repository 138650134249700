<template>
  <div id="profile-page">
    <div class="profile center">
      <div class="user-card-wrap">

        <div class="profile__card user-card">
          <img v-if="photoUrl" v-bind:src="photoUrl" alt="Аватар" class="user-card__avatar" />
          <img v-if="photoUrl === '' && theme === 'light'" src="/assets/icons/no-photo-white.png" alt="Аватар"
            class="user-card__avatar" />
          <img v-if="photoUrl === '' && theme === 'dark'" src="/assets/icons/no-photo-black.png" alt="Аватар"
            class="user-card__avatar" />
          <h1 class="user-card__name">{{ fullName }}</h1>

          <template v-if="status === 'new'">
            <div class="subscription-status user-card__subscription-status subscription-status_yellow">
              <span class="subscription-status__emoji" style="background-image: url('/assets/icons/emoji-nosub.svg')">
              </span>
              <span class="subscription-status__text">Нет подписки</span>
            </div>
            <router-link to="/tariffs-list" class="button ">Оформить подписку</router-link>
          </template>

          <template v-if="status === 'debtor' && paymentSystem === 'tinkoff'">
            <div class="subscription-status user-card__subscription-status subscription-status_red">
              <span class="subscription-status__emoji"
                style="background-image: url('/assets/icons/emoji-sub-notactive.svg')">
              </span>
              <span class="subscription-status__text">Подписка не продлена</span>
            </div>
            <a v-on:click="activateSubscription" href="#" class="button ">Продлить подписку</a>
          </template>

          <template v-if="status === 'old' || status === 'hold exit'">
            <div class="subscription-status user-card__subscription-status subscription-status_blue">
              <span class="subscription-status__emoji"
                style="background-image: url('/assets/icons/emoji-sub-notactive.svg')">
              </span>
              <span class="subscription-status__text">Подписка не активна</span>
            </div>
            <router-link to="/tariffs-list" class="button   button_blue ">Активировать подписку</router-link>
          </template>
        </div>
      </div>

      <nav class="menu">
        <div class="menu-item-wrap">
          <router-link to='/profile-edit' class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/change.svg')"></span>
            </span>
            <div class="menu-item__text">Изменить данные</div>
          </router-link>
        </div>
        <div class="menu-item-wrap">
          <router-link v-if="status === 'new' || status === 'old' || status === 'hold exit'" to="/tariffs-list" 
          class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/sub.svg')"></span>
            </span>
            <div class="menu-item__text">Управление подпиской</div>
          </router-link>
          <router-link v-if="status === 'active' || status === 'debtor'" to="/tariff-info" 
          class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/sub.svg')"></span>
            </span>
            <div class="menu-item__text">Управление подпиской</div>
          </router-link>
        </div>
        <!-- <div class="menu-item-wrap">
          <router-link to="/history" class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/history.svg')"></span>
            </span>
            <div class="menu-item__text">История поиска</div>
          </router-link>
        </div> -->
        <!-- <div class="menu-item-wrap">
          <router-link to="/favorites" class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/fav.svg')"></span>
            </span>
            <div class="menu-item__text">Избранное</div>
          </router-link>
        </div> -->
        <!-- <div class="menu-item-wrap">
          <router-link to="/documents" class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/docs.svg')"></span>
            </span>
            <div class="menu-item__text">Документы</div>
          </router-link>
        </div> -->
        <div class="menu-item-wrap">
          <router-link to="/receipts" class="menu-item menu__element">
            <span class="menu-item__icon menu-item__icon_blue">
            <span class="menu-item__icon_img" style="background-image: url('/assets/icons/check.svg')"></span>
            </span>
            <div class="menu-item__text">Чеки</div>
          </router-link>
        </div>
      </nav>
    </div>
  </div>
  <div class="modal modal_hide" id="modal">
    <div class="elipsis-modal"></div>
    <span class="modal__close-btn js-close-modal"></span>
    <div class="modal__icon icon__warning"></div>
    <div class="modal__heading cancel-sub-modal__heading"></div>
    <div class="modal__text cancel-sub-modal__text"></div>
    <a class="button cancel-sub__btn manage-sub__btn_gap js-cancel-sub-top-btn  button_blue "></a>
    <a class="button cancel-sub__btn js-cancel-sub-bottom-btn  button_transparent"></a>
  </div>
  <div class="modal modal_hide" id="modal-standart">
    <div class="modal__elipsis"></div>
  </div>
  <div class="blur-background">
  </div>
  <div id="notification" class="notification notification_hide">
    <div class="notification__text js-notification-text"></div>
    <div class="notification__close-button js-close-notification">Скрыть</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import tgTheme from '@/mixins/tgTheme';
import tgBackButton from '@/mixins/tgBackButton';
import activateTinkoffSubscription from '@/mixins/activateTinkoffSubscription';
export default {
  mixins: [tgTheme, tgBackButton, activateTinkoffSubscription],
  computed: {
    ...mapState({
      photoUrl: state => state.userData.photoUrl,
      status: state => state.userData.status,
      paymentSystem: state => state.userData.paymentSystem,
      userId: state => state.userData.id,
      paySucces: state => state.subscribeAction.isSucces,
      payFail: state => state.subscribeAction.isFail,
    }),
    ...mapGetters({
      fullName: 'userData/fullName',
    }),
  },
  methods: {
    ...mapMutations({
      invertSucces: 'subscribeAction/invertSucces',
      invertFail: 'subscribeAction/invertFail',
    }),
    activateSubscription(event) {
      event.preventDefault();
      this.activateTinkoffSubscription(this.userId);
    },
  },
  mounted() {
    this.tgBackButtonVuex('/');
    window.initModal();
    window.initNotification();
    if (this.paySucces) {
      window.openNotification('Подписка успешно оформлена', 'blue');
      this.invertSucces();
    }
    if (this.payFail) {
      window.openNotification('Подписка не оформлена, произошла ошибка', 'orange');
      this.invertFail();
    }
  },
}
</script>
