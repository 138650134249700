<template>
  <div class="history-card history_gap ">
    <span class="history-card__icon "></span>
    <div class="history-card__text ">{{ request }}</div>
    <div class="history-card__placeholder">
      <div class="history-card__placeholder_icon"
        style="-webkit-mask-image: url('/assets/icons/noimg.svg'); mask-image: url('/assets/icons/noimg.svg')"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    request: {
      type: String,
      required: true,
    },
  },
}
</script>
