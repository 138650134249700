<template>
  <swiper
    slides-per-view="auto"
    :space-between="10"
    :free-mode="true"
    :autoHeight="true"
  >
    <swiper-slide v-for="review in reviews">
      <div class="ta-sm-reviews__card">
        <div class="ta-sm-reviews__card-author">
          <div class="ta-sm-reviews__card-img">
            <img :src="review.img" alt="">
          </div>
          <h3 class="ta-sm-reviews__card-name">{{ review.name }}</h3>
        </div>
        <div class="ta-sm-reviews__card-text">{{ review.desc }}</div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data: () => ({
      reviews: [
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          name: 'Иван Филиппов',
          desc: '«Лунный рыцарь» куда сильнее углубляется в мистику и мифологию, чем прошлые проекты студии. Скорее всего, сериал с Оскаром Айзеком откроет для киновселенной Marvel новое жанровое направление',
        },
        {
          img: 'https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=300',
          name: 'Иван Филиппов',
          desc: '«Лунный рыцарь» куда сильнее углубляется в мистику и мифологию, чем прошлые проекты студии. Скорее всего, сериал с Оскаром Айзеком откроет для киновселенной Marvel новое жанровое направление',
        },
      ]
    }),
  }
</script>
