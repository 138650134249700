<template>
  <button class="subscription-btn subscription__item" v-bind:onclick="payAction" v-bind:data-name="tariff.title">
    <div class="subscription-btn_blur"></div>
    <img class="subscription-btn__image" v-bind:src="`/assets/icons/${tariff.title}.png`" />
    <div class="subscription-btn__desc">
      <div class="subscription-btn__name">
        {{ tariff.title }}
        <span v-if="tariff.currentFlag" class="subscription-btn__special subscription-btn__special_green">
          Текущий
        </span>
      </div>
      <div class="subscription-btn__length">{{ tariff.titlePeriod }}</div>
    </div>
    <div class="subscription-btn__price">{{ tariff.price }} </div>
  </button>
</template>

<script>
import { mapState } from 'vuex';
import payCloudpayments from '@/mixins/payCloudpayments';
import payTinkoff from '@/mixins/payTinkoff';
export default {
  mixins: [payCloudpayments, payTinkoff],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      id: state => state.userData.id,
      status: state => state.userData.status,
      currentTariffTitle:  state => state.userData.tariff.title,
    }),
  },
  methods: {
    payAction() {
      if (this.tariff.currentFlag) {
        window.openNotification(`Подписка ${this.tariff.title} уже активна!`, "orange", 2000);
      }
      if (!this.tariff.currentFlag && this.status === 'active') {
        window.tariffChange.openApproveModal({
          text: `Вы действительно хотите поменять тариф на ${this.tariff.title}?`,
          yesText: 'Да, поменять',
          noText: `Нет, оставить ${this.currentTariffTitle}`,
          onClose: () => {
            // console.log('Окно закрыто');
          },
          onApprove: () => {
            // this.payCloudpayments(this.id, this.tariff); // переделать на передачу ключа?
            this.payTinkoff(this.id, this.tariff.key);
          },
        });
      }
      if (!this.tariff.currentFlag && this.status !== 'active') {
        this.payTinkoff(this.id, this.tariff.key);
      }
    }
  },
}
</script>

window.tariffChange.onClickTariff = (tariffName) => {
  const currentTariff = 'NEW';
  if (tariffName === currentTariff) {
    window.openNotification(`Подписка ${tariffName} уже активна!`, "orange", 2000);
    return;
  }
  window.tariffChange.openApproveModal({
    text: "Вы действительно хотите поменять тариф на " + tariffName + "?",
    yesText: 'Да, поменять',
    noText: 'Нет, оставить ' + currentTariff,
    onClose: () => {
      console.log('Окно закрыто');
    },
    onApprove: () => {
      console.log('Выбор подтвержден');
    },
  });
},